@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, table {
        //font-weight: 400;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        margin-top: pxToRem(28);
         margin-bottom: pxToRem(28);
        // line-height: 1.2;
    }

    h5, .h5, h6, .h6 {
        //margin-bottom: pxToRem(16);
        //line-height: 1.3;
    }

    h2, .h2 {
        //  font-size: pxToRem(40);
    }

    h3, .h3 {
        //  font-size: pxToRem(30);
        margin: $spacing-xs 0 12px 0;
    }

    h4, .h4 {
        //  font-size: pxToRem(25);
    }

    h5, .h5 {
        // font-size: pxToRem(20);
    }

    h6, .h6 {
        //   font-size: pxToRem(16);
    }

    p {
        //line-height: 1.6em;
        font-weight: 300 !important;
        &:not(:last-child) {
            margin: 12px 0;
        }
    }

    p, ul, ol, hr, table {
        //margin-bottom: pxToRem(24);
    }

    strong {
        font-weight: 400;
    }

    hr {
        // border-top: 1px solid $color-dark;
    }

    hr {
        width: 90px;
        margin: $spacing-xs 0 $spacing-xs !important;
        border-bottom: 1px solid $color-black;
    }

    a {
        //text-decoration: underline;
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
    }

    ol li {
        position: relative;
        padding-left: pxToRem(10);
    }

    ul {
        padding-left: pxToRem(18);
        margin: $spacing-s 0;
        list-style-type: disc;
    }

    ul li {
        padding-left: pxToRem(10);
        position: relative;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    //ul li:before {
    //    content: '\00a0';
    //    position: absolute;
    //    top: 0;
    //    left: -1.2rem;
    //    display: block;
    //    width: 1.2rem;
    //    height: 1.6rem;
    //    background-image: inline-svg('check', $color-gray-60);
    //    background-repeat: no-repeat;
    //    background-size: 0.8rem 0.8rem;
    //    background-position: 0 center;
    //}
    //
    //ul li::marker,
    //ol li::marker {
    //    font-size: pxToRem(16);
    //    color: $color-primary;
    //    border-radius: 50% 50% 0 50%;
    //}

    ul li::marker {
        content: none;
    }

    ul li::before {
        content: '';
        position: absolute;
        left: -1.2rem;
        top: 10px;
        width: 6px;
        height: 6px;
        background-color: $color-primary;
        border-radius: 3px 3px 0 3px;
    }

    ol ol, ul ul, ol ul, ul ol {
        margin-bottom: 0;
    }

    table {
        text-align: left;
        //border: 1px solid $color-gray-15;
        width: 100%;
        margin: $spacing-m 0;
    }

    table th,
    table td,
    table thead th,
    table tbody td {
        text-align: left;
        background: transparent;
        min-width: 150px;
    }

    table th {
        font-weight: 500;
        p {
            color: $font-color-dark;
        }
    }

    table td {
        font-weight: 300;
    }

    tr:first-child td {
        border-bottom: 3px solid $color-primary;

        p {
            color: $font-color-dark;
        }
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-secondary;
    }

    tr:last-child {
        border-bottom: 2px solid $color-primary;
    }

    thead tr:last-child {
        border-bottom: 3px solid $color-primary;
    }

    th, td {
        padding: 12px 0;
    }

    th:not(:last-child),
    td:not(:last-child) {
        //border-right: 1px solid $color-gray-10;
        padding-right: 12px;
    }
    em {
        font-style: italic;
    }

}
