@import "../icon/sass-inline-svg";

/**
 * Default
 */

[data-page] [data-id]:not(.visible) {
    display: none;
}

.teasermatrix {
    margin: calc(96px - 24px) 0;
}

.teaser {
    position: relative;
}

.teaser-item__container {
    padding-top: 24px;
    padding-bottom: 24px;
}

.teaser__item, .list__item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: $font-color;
    background-color: $color-white;
    box-shadow: 0 2px 12px rgba($color-black, .3);
    transition: box-shadow .4s ease;

    &:hover {
        box-shadow: 0 2px 42px rgba($color-black, .3);
    }
}

.teaser__image, .list__image {
    position: relative;
}

.teaser__price, .list__price {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
    padding: 5px $spacing-xs 6px $spacing-xs;
    background-color: $color-white;
    border-radius: 40px 40px 4px 40px;
    color: $font-color-dark;

    .price__prefix, .price__suffix {
        font-size: pxToRem(13);
        line-height: pxToRem(16);
        font-weight: 300;
    }

    .price__price {
        font-size: pxToRem(20);
    }
}

.teaser__body, .list__body {
    display: flex;
    flex-direction: column;
    //flex: 1 1 auto;
    padding: 24px 24px $spacing-xs 16px;
}

.teaser__headline, .list__headline {
    margin-bottom: 8px;
    color: $font-color-dark;
}

.teasertext {
    display: -webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: pxToRem(15);
    line-height: 150%;
    font-weight: 300;
}

.teaser__readmore, .list__readmore {
    padding-top: 16px;
    margin-top: auto;
}

/* || Teaser green */

.teaser__item--green .teaser__image .image, .list__item--green .list__image .image, .teaser__item--green .placeholder, .list__item--green .placeholder {
    width: 240px;
    height: 240px;
    margin-bottom: $spacing-s;
    border: 1px solid #81D06D;
    border-radius: 50% 50% 4px 50%;
    box-shadow: 0 2px 12px rgba($color-black, .3);
    overflow: hidden;

    img {
        border-radius: 120px 120px 4px 120px;
    }
}

.teaser__item--green, .list__item--green {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spacing-s $spacing-l;
    background-color: $color-primary-dark;
    color: $font-color-light;
    box-shadow: 0 4px 32px rgba($color-black, .16);

    &:hover {
        box-shadow: 0 4px 32px rgba($color-black, .16);
    }

    .teaser__body, .list__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 0;
        text-align: center;
    }

    .teaser__label, .facts__outer, .list__label {
        display: none;
    }

    .teaser__headline--title {
        margin-bottom: 8px;
        font-size: pxToRem(17);
        line-height: 120%;
        font-weight: 400;
        color: $font-color-light;
    }

    .teaser__headline--headline {
        margin-bottom: 16px;
        font-size: pxToRem(28);
        line-height: 120%;
        font-weight: 500;
        color: $font-color-light;
    }

    .teasertext {
        -webkit-line-clamp: 100;
    }

    .teaser__readmore, .list__readmore {
        margin-top: auto;
        height: auto !important;
        color: $font-color-light !important;
    }
}

@media (min-width: 1921px) {
    .teaser__item--green {
        .teaser__image {
            flex: 1 1 auto;
            width: 90%;
            height: auto;
            padding: 0 $spacing-l;
        }

        .teaser__image .image, .placeholder {
            width: 90%;
            height: auto;
            margin: 0 auto $spacing-s;
            //width: 250px;
            //height: 250px;
            border-radius: 50% 50% 4px 50%;

            img {
                border-radius: 50% 50% 4px 50%;
            }
        }
    }
}

@media (min-width: 1701px) and (max-width: 1850px) {
    .teaser__item--green, .list__item--green {
        padding: $spacing-s $spacing-s;
        .teasertext {
            -webkit-line-clamp: 4;
        }
    }
}

@media (max-width: 1700px) {
    .teaser__item--green, .list__item--green {
        padding: $spacing-s $spacing-s;
        .teasertext {
            -webkit-line-clamp: 3;
        }
    }

    .teaser__item--green .teaser__image .image, .teaser__item--green .placeholder {
        width: 200px;
        height: 200px;

        img {
            border-radius: 100px 100px 4px 100px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .list__item--green {
        .teasertext {
            -webkit-line-clamp: 5;
            max-width: 400px;
        }

        .list__image {
            flex: 1 1 auto;
            width: 90%;
            height: auto;
            padding: 0 $spacing-l;
        }

        .list__image .image, .placeholder {
            width: 90%;
            height: auto;
            margin: 0 auto $spacing-s;
            //width: 250px;
            //height: 250px;
            border-radius: 50% 50% 4px 50%;

            img {
                border-radius: 50% 50% 4px 50%;
            }
        }
    }
}

@media (min-width: 1500px) {
    .list__item--green {
        .teasertext {
            max-width: initial;
        }
    }
}

@include media-breakpoint-down(xl) {
    .teaser__item--green, .list__item--green {
        padding: $spacing-s 16px;
        .teasertext {
            -webkit-line-clamp: 2;
        }
    }

    .list__item--green {
        padding: $spacing-s;
        .list__body {
            max-width: 400px;
        }
        .teasertext {
            -webkit-line-clamp: 4;
        }
    }
}

@include media-breakpoint-down(lg) {
    .teaser__item--green, .list__item--green {
        padding: $spacing-s 16px;
        .teasertext {
            -webkit-line-clamp: 2;
        }
    }

    .list__item--green {
        .teasertext {
            -webkit-line-clamp: 3;
        }
    }
}

@include media-breakpoint-down(md) {
    .teaser__item--green, .list__item--green {
        //height: 90vh;

        .teasertext {
            -webkit-line-clamp: 4;
            max-width: 500px
        }
    }
}

@include media-breakpoint-down(sm) {
    .teasermatrix {
        margin: calc(72px - 24px) 0;
    }

    .teaser__body, .list__body {
        padding: 24px 16px 28px;
    }

    .teasermatrix .teaser__readmore, .teasermatrix .list__readmore {
        padding-top: 16px;
    }

    .teaser__price, .list__price {
        top: 14px;
        right: 14px;
    }

    .teaser__item--green, .list__item--green {
        padding: 36px 16px;
        height: auto !important;

        .teasertext {
            -webkit-line-clamp: 2;
            max-width: 400px
        }
    }

    .teaser__item--green .teaser__readmore, .list__item--green .list__readmore {
        padding-top: 36px;
    }

    .slider {
        .teaser__item--green .teaser__image .image, .list__item--green .list__image .image, .teaser__item--green .placeholder, .list__item--green .placeholder {
            width: 150px;
            height: 150px;
        }
    }
}
