/**
 * Import media breakpoints and other shraed vars (js/css)
 */
@import '@src-sx/theme/scss/shared-vars';

/**
 * Use autogenerated media
 */
$media-steps: $sx-breakpoints;

/**
 * Mixin vars
 */
$grid-steps: (
        1, 6, 10, 12
);

/**
* individual gutter, breakpoint independent
*/
$grid-gutters: (
    //10px, 20px
    48px
);

/**
 * breakpoint dependent gutter
 */
$grid-breakpoint-gutters: (
        'xs': 10px,
        'sm': 10px,
        'md': 48px,
        'lg': 48px,
        'xl': 48px,
        'xxl': 48px,
);

/**
 * Container
 */

$container-paddings: (
        'xs': 14px,
        'sm': 14px,
        'md': 48px,
        'lg': 48px,
        'xl': 48px,
        'xxl': 96px,
);

$image-ratio-steps: (
        1, 2, 3, 4, 5, 6, 7, 9, 16
);

/**
 * Border radius vars
 */

$border-radius: 0px !default;
$border-radius-button: 5px;
/* If border-radius-button is not defined somewhere else, use border-radius as default */
$border-radius-button: $border-radius !default;

/**
 * Sx vars
 */

$sx-path-images: '/dist/bundle/img/';

$header-height: 80px;

/**
 * Font vars
 */
$sx-path-fonts: '/dist/bundle/fonts/';
$base-font-size: 16px;
$font01: 'HD Colton', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font02: '---', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

/**
 * Spacing vars
 */

$spacing-xs: 28px;
$spacing-s: 36px;
$spacing-m: 48px;
$spacing-l: 64px;
$spacing-xl: 96px;
$spacing-xxl: 144px;

$spacing-mobile: 72px;

/**
 * Color vars
 */

$font-color: #4D4D4D;
$font-color-light: #FFFFFF;
$font-color-dark: #000000;

$color-primary: #4AA134;
$color-primary-dark: #428F2E;
$color-primary-hover: $color-primary-dark;

$color-secondary: #666666;
$color-secondary-hover: lighten($color-secondary, 20%) !default;

$color-tertiary: #4D4D4D;

$color-background: #EBEBEB;
$color-background-light: #F7F7F7;

$color-success: #3ed786;
$color-warning: #ffac2f;
$color-danger: #fd2f51;
$color-info: #37C4EF;

$color-white: #FFFFFF;
$color-black: #000000;

//die Farben müssen soviel Kontrast haben, dass sie als BG und Fontcolor funktionieren
$color-light: #F9FCFF;
$color-dark: #4B525B;

$color-gray-05: mix($color-black, $color-white, 0.05 * 100%);
$color-gray-10: mix($color-black, $color-white, 0.10 * 100%);
$color-gray-15: mix($color-black, $color-white, 0.15 * 100%);
$color-gray-20: mix($color-black, $color-white, 0.20 * 100%);
$color-gray-25: mix($color-black, $color-white, 0.25 * 100%);
$color-gray-30: mix($color-black, $color-white, 0.30 * 100%);
$color-gray-35: mix($color-black, $color-white, 0.35 * 100%);
$color-gray-40: mix($color-black, $color-white, 0.40 * 100%);
$color-gray-45: mix($color-black, $color-white, 0.45 * 100%);
$color-gray-50: mix($color-black, $color-white, 0.50 * 100%);
$color-gray-55: mix($color-black, $color-white, 0.55 * 100%);
$color-gray-60: mix($color-black, $color-white, 0.60 * 100%);
$color-gray-65: mix($color-black, $color-white, 0.65 * 100%);
$color-gray-70: mix($color-black, $color-white, 0.70 * 100%);
$color-gray-75: mix($color-black, $color-white, 0.75 * 100%);
$color-gray-80: mix($color-black, $color-white, 0.80 * 100%);
$color-gray-85: mix($color-black, $color-white, 0.85 * 100%);
$color-gray-90: mix($color-black, $color-white, 0.90 * 100%);
$color-gray-95: mix($color-black, $color-white, 0.95 * 100%);


$color-white-light: lighten($color-white, 5%) !default;
$color-white-lighter: lighten($color-white, 10%) !default;

$color-white-dark: darken($color-white, 5%) !default;
$color-white-darker: darken($color-white, 10%) !default;


$color-black-light: lighten($color-black, 5%) !default;
$color-black-lighter: lighten($color-black, 10%) !default;

$color-black-dark: darken($color-black, 5%) !default;
$color-black-darker: darken($color-black, 10%) !default;


$color-light-light: lighten($color-white, 5%) !default;
$color-light-lighter: lighten($color-white, 10%) !default;

$color-light-dark: darken($color-white, 5%) !default;
$color-light-darker: darken($color-white, 10%) !default;


$color-dark-light: lighten($color-white, 5%) !default;
$color-dark-lighter: lighten($color-white, 10%) !default;

$color-dark-dark: darken($color-white, 5%) !default;
$color-dark-darker: darken($color-white, 10%) !default;


$color-primary-light: lighten($color-primary, 5%) !default;
$color-primary-lighter: lighten($color-primary, 10%) !default;

$color-primary-dark: darken($color-primary, 5%) !default;
$color-primary-darker: darken($color-primary, 10%) !default;


$color-secondary-light: lighten($color-secondary, 5%) !default;
$color-secondary-lighter: lighten($color-secondary, 10%) !default;

$color-secondary-dark: darken($color-secondary, 5%) !default;
$color-secondary-darker: darken($color-secondary, 10%) !default;


$color-success-light: lighten($color-success, 5%) !default;
$color-success-lighter: lighten($color-success, 10%) !default;

$color-success-dark: darken($color-success, 5%) !default;
$color-success-darker: darken($color-success, 10%) !default;


$color-warning-light: lighten($color-warning, 5%) !default;
$color-warning-lighter: lighten($color-warning, 10%) !default;

$color-warning-dark: darken($color-warning, 5%) !default;
$color-warning-darker: darken($color-warning, 10%) !default;


$color-danger-light: lighten($color-danger, 5%) !default;
$color-danger-lighter: lighten($color-danger, 10%) !default;

$color-danger-dark: darken($color-danger, 5%) !default;
$color-danger-darker: darken($color-danger, 10%) !default;


$color-info-light: lighten($color-info, 5%) !default;
$color-info-lighter: lighten($color-info, 10%) !default;

$color-info-dark: darken($color-info, 5%) !default;
$color-info-darker: darken($color-info, 10%) !default;


$color-background-light: lighten($color-background, 10%) !default;
$color-background-lighter: lighten($color-background, 20%) !default;

$color-background-dark: darken($color-background, 10%) !default;
$color-background-darker: darken($color-background, 20%) !default;
