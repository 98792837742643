.list {
    margin: calc(96px - 48px) 0;
}

.list__pagination {
    margin-top: 20px;
}

.list__item--container {
    padding: 48px 0;
}

.list__label, .teaser__label {
    position: absolute;
    bottom: 20px;
    z-index: 5;
    padding: 13px 25px;
    font-size: pxToRem(20);
    line-height: 120%;
    color: $font-color-light;
    background-color: $color-primary;
    border-radius: 0 25px 0 0;
}

@include media-breakpoint-down(sm) {
    .list {
        margin: calc(72px - 24px) 0;
    }

    .list__item--container {
        padding: 24px 0;
    }
}