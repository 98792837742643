.header__navigation {
    //margin-top: 60px;
    flex: 1;
    pointer-events: none;
}

.header__navigation .header__navigation-wrapper > ul {
    //width: calc(100% + 16px);
    width: 100%;
    //margin: 0 -8px;

    ul {

        //@include media(lg) {
        @media(min-width: 1325px) {
            opacity: 0;
            pointer-events: none;
            display: block;
            position: absolute;
            top: 57px;
            left: 50%;
            //min-width: 200px;
            //width: calc(100% + 16px);
            margin: 0 -8px;
            transform: translateX(calc(-50% + 8px));
            transition: opacity .4s ease;
            background-color: $color-white;
            &:before {
                content: '';
                position: absolute;
                height: 31px;
                width: 100%;
                top: -31px;
            }
        }
    }
}

.header__navigation .header__navigation-wrapper > ul > li {
    display: inline-block;
    padding: 0 25px;
    position: relative;
    min-width: 62px;
    width: auto;

    &:not(:first-child) {
        //margin-left: 34px;
    }

    &:hover ul {
        opacity: 1;
        pointer-events: auto;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08));
    }
}

@media(max-width: 1450px) {
    .header__navigation .header__navigation-wrapper > ul > li:not(:first-child) {
        //margin-left: 7px;
        padding: 0 12px;
    }
}

.header__navigation .header__navigation-wrapper ul > li a {
    font-size: pxToRem(17);
    font-weight: 500;
    color: $font-color-light;
}

.header__navigation .header__navigation-wrapper ul ul li {
    display: block;
    padding: 0 28px 0 18px;
    margin-left: initial !important;

    &:first-child {
        margin-top: $spacing-xs;
    }

    &:last-child {
        margin-bottom: $spacing-xs;
    }
}

.header__navigation .header__navigation-wrapper ul ul > li a {
    position: relative;
    left: 0;
    display: inline-block;
    padding: 8px 26px;
    font-size: pxToRem(15);
    line-height: 160%;
    font-weight: 300;
    color: $font-color-dark;
    white-space: nowrap;
    transition: .4s ease;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 15px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-image: inline-svg('arrow-s', $font-color-dark);
        background-size: 10px 10px;
        background-position: center center;
        background-repeat: no-repeat;
        transition: all .3s ease;
    }

    &:hover {
        left: 8px;
    }

    &:hover::before {
        left: -7px;
        width: 16px;
        height: 10px;
        background-image: inline-svg('arrow-s-long', $color-primary);
        background-size: 21px 10px;
    }
}

.burger {
    display: none;
    transition: all 1s ease;
}

.plus {
    display: none;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    transition: transform .4s ease
}

.plus:before {
    transition: all .6s ease;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: '';
    width: 50px;
    height: 50px;
    background-image: url('/src/static/img/plus.svg');
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 20px 20px;
    background-size: 20px 20px;
}

.header__navigation .level-0 {
    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(calc(-50%));
        display: block;
        height: 2px;
        width: 0;
        background-color: $color-primary;
        opacity: 0;
        transition: all .4s ease;
    }

    &:hover::after {
        width: calc(100% - 50px);
        opacity: 1;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: -31px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #FFFFFF transparent;
        transition: opacity .4s ease;
        opacity: 0;
    }

    &.has-visible-children:hover::before {
        opacity: 1;
        z-index: 1;
    }
}

/* || Header Button Container */

.header__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 270px;
    margin-top: 45px;
}

.buttons__container {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
}

.header__booknow {
    margin-top: $spacing-xs;
    align-self: flex-end;
    width: 260px;
    &:hover {
        width: 265px;
    }
}

/* || Iconbar */

.header__iconbar {
    display: flex;

    a {
        position: relative;
        width: 34px;
        height: 34px;
        background-color: $color-primary;
        background-size: 25px 25px;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 50% 50% 3px 50%;

        &:not(:first-child) {
            margin-left: 16px;
        }
    }
}

.icon__phone {
    background-image: inline-svg('phone', $color-white, transparent);
}

.icon__phone {
    transition: all .3s ease;
}

.icon__phone.active {
    background-color: $color-primary-hover;
    border-radius: 50% 50% 50% 3px;
}

[data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 0;
    top: -100%;
    z-index: 10;
    width: max-content;
    padding: 0 5px;
    font-size: pxToRem(16);
    line-height: 150%;
    color: $font-color;
    background-color: $color-background-light;
    border-radius: 12px 12px 12px 2px;
    box-shadow: 0 2px 12px rgba($color-black, .2);
    opacity: 0;
    transition: opacity .3s ease;
    pointer-events: none;
}

.icon__phone.active[data-tooltip]::after {
    opacity: 1;
}

.js-nav-scrolled02 [data-tooltip]::after, .header__scrolled--mobile [data-tooltip]::after {
    top: initial;
    bottom: -2px;
    left: -8px;
    transform: translateX(-100%);
    background: transparent;
    box-shadow: none;
}


.icon__email {
    background-image: inline-svg('envelope', $color-white, transparent);
}

.icon__anfahrt {
    background-image: inline-svg('place', $color-white, transparent);
}

/* || Language */

.header__language {
    margin-left: $spacing-xs;
    height: 100%;
}

.language {
    height: 100%;

    ul {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }
}

.language span, .language a {
    width: 100%;
    color: $font-color-light;
    text-transform: uppercase;
    font-size: pxToRem(16) !important;
}

.language a {
    display: inline-block;
    font-weight: 400 !important;
    transition: .4s ease;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $color-primary;
        opacity: 0;
        transition: .4s ease;
    }

    &:hover {
        transform: translateY(-3px);

        &::after {
            opacity: 1;
        }
    }
}

/* || Scrolled */

.js-nav-scrolled02 {
    .header__navigation {
        //margin-top: 37px;

        .header__navigation-wrapper ul > li > a {
            color: $font-color-dark;
        }

        .level-0::after {
            bottom: -7px;
        }
    }

    .buttons__container {
        align-items: initial;
    }

    .header__buttons {
        margin-top: 26px;
    }

    .header__language {
        span, a {
            color: $font-color-dark;
        }
    }

    .header__booknow {
        margin-top: $spacing-m;
        box-shadow: 0 2px 12px rgba($color-black, .3);
    }
}

.header__navigation .header__navigation-wrapper {
    display: flex;
    flex: 1;

    > ul {
        display: inline-flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 60px;
        pointer-events: auto;
    }

    .level-0 {
        white-space: nowrap;
    }

    .header__buttons {
        display: inline-flex;
        margin-top: 50px;
        pointer-events: auto;
    }

    .language ul li {
        margin-left: 0 !important;
    }
}

.js-nav-scrolled02 .header__navigation .header__navigation-wrapper {
    > ul {
        height: 80px;
        padding-top: 37px;
        margin-top: initial;
    }

    .header__buttons {
        margin-top: 26px;
    }
}

.header__navigation--mobile, .header__scrolled--mobile {
    display: none;
    pointer-events: auto;
}

.header__navigation .level-0.home {
    display: none;
}


@media(max-width: 1325px) {
    .header__navigation .header__navigation-wrapper > ul > li:not(:first-child) {
        padding: 15px 0;
    }

    .js-nav-open {
        overflow: hidden;
    }

    .header__navigation {
        pointer-events: auto;
    }

    .header__navigation .level-0.home {
        display: block;
    }

    .burger {
        display: block;
        position: absolute;
        right: 12px;
        top: 55px;
        z-index: 600;
    }

    .js-nav-scrolled02 .burger {
        top: 23px;
    }

    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        width: 27px !important;
        height: 3px !important;
        background-color: $color-white !important;
    }

    .js-nav-scrolled02 {
        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
            background-color: $color-primary !important;
        }
    }

    .plus {
        display: block;
    }
    .js-subnav-open .plus {
        transform: rotate(-45deg);
    }
    .js-nav-open {
        .header__navigation {
            left: 0;
        }
    }

    .js-nav-scrolled02 .header__navigation .header__navigation-wrapper {
        margin-top: initial;

        > ul {
            height: auto;
            padding-top: initial;
            margin-top: $spacing-xs;
        }

        > ul > li a {
            color: $font-color-light;
        }
    }

    .js-nav-scrolled02 .header__scrolled--mobile {
        position: absolute;
        bottom: 18px;
        right: 74px;
        display: flex;

        .icon__phone {
            position: relative;
            width: 34px;
            height: 34px;
            background-size: 25px 25px;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: $color-primary;
            border-radius: 50% 50% 3px 50%;
        }

        .button--booknow {
            height: 34px !important;
            margin-left: 8px;
            font-size: pxToRem(15) !important;
        }
    }

    .header__navigation-wrapper {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
    }


    .header__navigation {
        position: fixed;
        z-index: 550;
        left: -100vw;
        top: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        background: $color-tertiary;
        -ms-overflow-style: scrollbar;
        overflow-style: scrollbar;
        display: flex;
        //flex-direction: column-reverse;
        //justify-content: flex-end;
        //align-items: flex-start;
        align-items: center;
        padding: 18px 14px 100px;
        margin-top: initial;
        background: $color-tertiary;
        transition: all .6s ease;
        overflow-y: auto;

        &.phonenumber--active {
            padding-top: 50px;
        }

        .header__navigation-wrapper > ul {
            display: inline-block;
            width: 100%;
            margin-top: $spacing-xs;

            li {
                display: block;
                text-align: initial;
                font-size: pxToRem(30);
                line-height: 120%;
                padding: 15px 0;
                margin-left: initial !important;

                a {
                    display: block;
                    width: 100%;
                    color: $font-color-light;
                }
            }
        }

        .header__navigation-wrapper ul ul {
            display: none;
            margin-top: initial;

            li {
                font-size: pxToRem(20);
                padding: 2px 0;

                &:first-child {
                    margin-top: 14px;
                }

                &:last-child {
                    margin-bottom: initial;
                }

                a {
                    font-size: pxToRem(16);
                    line-height: 160%;
                    font-weight: 500;
                    color: $font-color-light;
                    border-bottom: 1px solid $color-secondary;

                    &::before {
                        background-image: inline-svg('arrow-s', $color-primary);
                    }
                }
            }
        }

        .level-0::before, .level-0::after {
            content: none;
        }

        .level-0 > a {
            padding-bottom: 10px;
            font-size: pxToRem(22) !important;
            border-bottom: 2px solid $color-white;
        }

        .header__buttons {
            align-items: flex-start;
            min-width: initial;
            width: 100%;
            margin-top: initial !important;

            .buttons__container {
                align-self: initial;
                align-items: initial;
            }
        }

        .language ul {
            display: flex;
            margin-top: initial;

            li {
                position: relative;
                display: block;
                padding: 0 8px;
                text-align: initial;
                font-size: 1.875rem;
                line-height: 120%;
            }
        }

        .language span, .language a {
            font-size: pxToRem(16);
            font-weight: 400;
            color: $font-color-light !important;
        }
    }

    .header__navigation--mobile {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: $spacing-xs;
        font-weight: 300;
        color: $font-color-light;

        span {
            display: inline-block;
            margin-bottom: 2px;
            font-weight: 500;
        }

        .header__socials {
            display: flex;
            padding-bottom: 120px;
            margin: 20px 0;

            a {
                width: 50px;
                height: 50px;

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }
    }

    .header__booknow {
        display: none !important;
    }

    .js-nav-open .burger {
        position: fixed;
        top: 10px;
        right: 12px;

        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
            background-color: $color-white !important;
        }
    }

}

@include media-breakpoint-down(sm) {
    .header__logo {
        width: 200px;
        height: auto;
        margin-top: 30px;
    }

    .burger {
        top: 30px;
    }

    .js-nav-scrolled02 .header__logo--scrolled {
        width: 40px;
        height: auto;
        margin-top: 12px;
    }

    .js-nav-scrolled02 .header__scrolled--mobile {
        bottom: 9px;
    }

    .js-nav-scrolled02 .burger {
        top: 11px;
    }
}
