.highlights__outer {
    position: relative;
    padding: $spacing-s 0 0;
    margin: $spacing-m 0 $spacing-xl;
    background-color: $color-tertiary;
}

.highlights__container {
    display: flex;
    justify-content: space-evenly;
    padding: 12px 0 20px;
}

.highlights__heading {
    color: $font-color-light;
}

.highlights__topline {
    margin-bottom: 12px;
    color: $font-color-light;
}

.highlights__headline {
    margin-bottom: $spacing-s;
    color: $font-color-light;
}

.highlights {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
        color: $font-color-light;
    }
}

.icon--big {
    display: inline;
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
    border-radius: 40px 40px 4px 40px;
    box-shadow: 0 2px 12px rgba($color-black, .2);
}

.view-room .highlights__outer {
    padding-top: $spacing-xs;

    .highlights__container {
        padding-bottom: $spacing-s;
    }
}

@include media-breakpoint-down(xl) {
    .wischicon--highlights::after {
        content: '';
        position: absolute;
        right: 25px;
        top: -25px;
        width: 45px;
        height: 45px;
        background-image: inline-svg('hand-scroll', $color-black);
        background-size: 30px 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $color-white;
        border: 1px solid $color-white;
        border-radius: 50%;
    }

    .highlights__room .wischicon--highlights::after {
        top: -89px;
    }

    .highlights__inner.container {
        padding: 0 !important;
    }

    .highlights__container {
        display: block;
        white-space: nowrap;
        overflow-x: auto;
    }

    .highlights {
        display: inline-flex;
        //width: calc(25% - 48px);
        margin-left: 48px;
        text-align: initial;
        white-space: normal;
    }
}

@include media-breakpoint-down(lg) {
    .highlights {
        width: calc(27% - 48px);
    }
}

@include media-breakpoint-down(sm) {
    .highlights__outer {
        margin: 46px 0;
    }

    .highlights__room .wischicon--highlights::after {
        top: -87px;
    }

    .highlights {
        width: calc(39% - 14px);
        margin-left: 14px;

        span {
            font-size: pxToRem(15);
        }
    }
}