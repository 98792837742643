
.footer {
    position: relative;
    width: 100%;
    padding: $spacing-l 0;
    margin-top: 180px;
    color: $font-color-light;
    background-color: $color-tertiary;

    h4 {
        color: $font-color-light;
    }
}

/* || Footer Kontakt */

.contact__container {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-xs;
}

.contact__name {
    font-weight: 500;
}

.contact__address {
    margin-top: 8px;
    font-weight: 300;
}

.contact__phone, .contact__email {
    font-weight: 500;

    a {
        margin-left: 16px;
        color: $font-color-light;
        font-weight: 300;
    }
}

.contact__phone {
    margin-top: 8px;
}

.contact__email a {
    text-decoration: underline;
}

/* || Footer Newsletter */

.footer__newsletter--content {
    margin-top: $spacing-xs;
    font-weight: 300;
}

.footer-newsletter__form {
    margin-top: $spacing-xs;
    border-radius: 5px;

    .formular-item__input {
        height: 50px;
        padding: 16px;
        border: 1px solid $color-white;
        border-radius: 5px 0 0 5px !important;
    }

    .input-group-append {
        background-color: $color-primary !important;
        border-radius: 0 5px 5px 0 !important;
        transition: .4s ease;
    }

    .btn--footer-newsletter {
        width: 58px;
        height: 100% !important;
        border-radius: 0 5px 5px 0 !important;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 16px;
            padding: 10px;
            background-image: inline-svg('arrow-s', $color-white);
            background-size: 11px 11px;
            background-position: center center;
            background-repeat: no-repeat;
            border: 1px solid $color-white;
            border-radius: 50% 50% 0 50%;
            transition: .4s ease;
        }

        &:hover {
            background-color: $color-primary-hover !important;
        }

        &:hover::after {
            background-image: inline-svg('arrow-s', $color-black);
            background-color: $color-white;
            border-radius: 50% 50% 50% 0;
            cursor: pointer;
        }
    }
}

/* || Footer Navigation */

.footer__navigation {
    display: flex;
    //justify-content: center;
}

.footer__navigation ul {
    display: flex;
    flex-wrap: wrap;
    //flex-direction: column;
    //min-width: 160px;
    padding-left: 24px;
}

.footer__navigation ul > li {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    transition: .4s ease;
    //min-width: calc(100% + 5px);

    &:not(:last-child) {
        //margin-top: 10px;
        margin-right: $spacing-l;
    }

    &::after {
        content: none;
    }

    &:hover {
        padding-left: 12px;
    }
}

.footer__navigation ul > li a {
    font-size: pxToRem(15);
    color: $font-color-light;
    text-decoration: underline !important;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -24px;
        transform: translateY(-50%);
        width: 11px;
        height: 11px;
        padding: 10px;
        background-image: inline-svg('arrow-s', $color-white);
        background-size: 11px 11px;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $color-primary;
        border-radius: 50% 50% 0 50%;
        transition: .4s ease;
    }

    &:hover::before {
        margin-right: 16px;
        background-color: $color-primary-hover;
        border-radius: 50% 50% 50% 2px;
    }
}

/* || Footer Socials */

.socials__trustyou {
    img {
        width: auto;
    }

    .trustyou-seal {
        position: relative;
    }

    .trustyou-seal__logo {
        position: relative;
    }

    .trustyou-seal__text {
        position: absolute;
        width: 100%;
        //font-size: pxToRem(20);
        font-weight: 500;
        line-height: 130%;
        color: $font-color-light;
        span {
            position: absolute;
            top: 9px;
            left: 95px;
            font-size: clamp(0.813rem, calc(0.0256rem + 1.27vw), 1.25rem);
        }
    }
}

.socials__container {
    display: flex;
    margin-top: $spacing-s;
    a {
        width: 50px;
        height: 50px;
        &:not(:first-child) {
            margin-left: 16px;
        }
    }
}

.footer__awards {
    display: flex;
    margin-top: $spacing-s;

    .socials__hotelstars {
        width: 190px;
        height: 193px;
    }

    .socials__hotelstars {
        margin-right: 78px;
    }

    .socials__cp {
        display: inline-block;
        max-width: 300px;
        margin-bottom: 12px;
        margin-left: 12px;
    }

    .socials__trustyou {
        display: inline-block;
    }
}

@media(max-width: 1350px) {
    .footer__awards .socials__hotelstars {
        margin-right: 28px;
    }
}

@include media-breakpoint-down(xl) {
    .footer__awards .socials__hotelstars {
        width: 150px;
        height: 152px;
    }
}

@include media-breakpoint-down(lg) {
    .footer__awards .socials__hotelstars {
        width: 190px;
        height: 193px;
    }

    .footer__awards .socials__trustyou {
        margin-left: 12px;
    }

    .award__container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }
}

@include media-breakpoint-down(sm) {
    .footer__awards {
        flex-direction: column;
    }

    .socials__trustyou {
        margin-top: $spacing-s;
    }

    .footer__navigation ul {
        flex-direction: column;
    }

    .award__container {
        margin-top: $spacing-s;
    }
}

/* || Scrollbutton */

.scrollbtn--up {
    position: absolute;
    top: -98px;
    right: $spacing-xs;
    width: 70px;
    height: 70px;
    background-image: inline-svg('arrow-xl', $color-primary);
    background-size: 36px 36px;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid $color-primary;
    border-radius: 50% 50% 4px 50%;
    transition: .4s ease;

    &:hover {
        background-image: inline-svg('arrow-xl', $color-white);
        background-color: $color-primary-hover;
    }
}

@include media-breakpoint-down(lg) {
    .footer__newsletter {
        margin-top: $spacing-xs;
    }

    .footer__navigation {
        justify-content: flex-start;
        margin-top: $spacing-xs;
    }

    .footer__socials {
        margin-top: $spacing-xs;
    }

    .socials__container {
        margin-top: $spacing-xs;
    }
}