/**
 * Slider
 */

.swiper--test {
    position: relative;
    margin: auto;
}

.slider {
    position: relative;
    padding: 48px 0;
    margin: 48px 0;
    overflow-x: hidden;

    .container {
        position: initial;
    }

    .teaser__body {
        flex: 1 1 auto;
    }
}

.slider__navigation {
    width: 100%;
    height: 0;
}

.slider__prev,
.slider__next {
    position: absolute;
    top: calc(50% - 25px);
    display: block;
    width: 50px;
    height: 50px;
    background-color: rgba($color-black, 0.3);
    background-size: 16px 16px;
    background-position: center center;
    background-repeat: no-repeat;
}

.slider__prev {
    left: 0;
    background-image: inline-svg('angle-left', $color-white);
}

.slider__next {
    right: 0;
    background-image: inline-svg('angle-right', $color-white);
}

.teaser--slider .teaser__item {
    position: relative;
    height: 100%;
    display: block;
    width: 100%;
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: none;
}

.swiper-button-next, .swiper-button-prev {
    width: 70px;
    height: 70px;
    background-color: rgba($color-black, .2);
    background-image: inline-svg('arrow-l', $color-white);
    background-size: 38px 38px;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.swiper-button-next {
    right: $spacing-xs;
}

.swiper-button-prev {
    transform: rotate(180deg);
    left: $spacing-xs;
}

/* || Slider small */



/* || Slider big */

.slider--big .swiper-slide {
        padding-left: 48px !important;
        padding-right: 48px !important;
}

@include media-breakpoint-down(xl) {
    .slider--big .swiper-slide {
        padding: 0 24px !important;
    }
}

@include media-breakpoint-down(md) {
    .slider--big .swiper-slide, .slider--small .swiper-slide {
        padding: 0 14px !important;
    }
}

@include media-breakpoint-down(sm) {
    .swiper-wrapper {
        margin-left: -14px !important;
    }

    .slider--big .swiper-slide, .slider--small .swiper-slide {
        padding-left: 7px !important;
        padding-right: 14px !important;
    }

    .slider--big .container, .slider--small .container {
        padding-left: 0 !important;

        .swiper-button-prev {
            display: none;
        }
    }

    .slider {
        margin: calc(72px - 16px) 0;
    }
}

