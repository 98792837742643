
* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base-font-size;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    //line-height: 1.5;
    line-height: pxToRem(26);
}

body {
    overflow-x: hidden;
    font-family: $font01;
    font-weight: 400;
    color: $font-color;
    background-color: $color-background;
    background-image: url("/src/static/img/background.svg");
    background-size: cover;
}

.css-transitions-only-after-page-load * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
}

a {
    color: $color-primary;
    text-decoration: none !important;
    word-break: break-word;
}

address {
    font-style: normal;
}

table {
    //display: block;
    overflow-x: auto;
}

h1, .h1 {
    font-size: pxToRem(44);
    line-height: 120%;
    font-weight: 500 !important;
    color: $font-color-dark;
}

h2, .h2 {
    font-size: pxToRem(40);
    line-height: 120%;
    font-weight: 500;
    color: $font-color-dark;
}

h3, .h3 {
    font-size: pxToRem(28);
    line-height: 120%;
    font-weight: 500;
    color: $font-color-dark;
}

h4, .h4 {
    font-size: pxToRem(26);
    line-height: 120%;
    font-weight: 500;
    color: $font-color-dark;
}

h5, .h5 {
    font-size: pxToRem(18);
    line-height: 120%;
    font-weight: 500;
    color: $font-color-dark;
}

p {
    font-weight: 300;
    color: $font-color;
}

.chatbot {
    position: fixed;
    right: $spacing-xs;
    bottom: $spacing-xs;
    z-index: 100;
}

.copyright {
    margin-left: 36px;
}

.copyright ul.copyright--list {
    margin: 12px 0;
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }

    .copyright {
        margin-left: 0;
    }

    .container--mobile {
        padding: 0 8px;
    }

    table {
        display: block;
    }

    tbody {
        display: table;
        overflow-x: auto;
        width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    h1, .h1, h2, .h2 {
        font-size: pxToRem(32);
        line-height: 120%;
    }

    h3, .h3 {
        font-size: pxToRem(22);
        line-height: 120%;
    }

    p {
        font-size: pxToRem(15);
        line-height: 150%;
    }

    .chatbot {
        opacity: 0;
    }

    .js-nav-scrolled02 .chatbot {
        display: flex;
    }

    table {
        position: relative;

        //&::before {
        //    content: '';
        //    position: absolute;
        //    right: 16px;
        //    top: 16px;
        //    width: 34px;
        //    height: 34px;
        //    background-image: inline-svg('arrow-l', $color-black);
        //    background-size: 16px 16px;
        //    background-position: center center;
        //    background-repeat: no-repeat;
        //    background-color: $color-white;
        //    border-radius: 50%;
        //}
        //
        //&::after {
        //    content: '';
        //    position: absolute;
        //    right: 0;
        //    top: 0;
        //    width: 62px;
        //    height: 100%;
        //    background: linear-gradient(270deg, rgba($color-black, 0.08) 0%, rgba($color-black, 0) 100%);
        //}
    }
}





