.layout-error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: $color-white;
}

.error {
    width: 100%;
    //max-width: 640px;
    margin: 0 auto;
    margin-top: pxToRem(128);
    padding: 200px 0 110px;
    //text-align: center;
}

.error h1 {
    //color: $color-danger;
}

.error p {
    font-size: $base-font-size;
}

.view-error {
    .header::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg,rgba(0,0,0,.4),transparent);
        z-index: -1;
    }
}

.view-error.js-nav-scrolled, .view-error.js-nav-scrolled02 {
    header::before {
        content: none;
    }
}

@include media-breakpoint-down(sm) {
    .view-error {
        .header::before {
            height: 170%;
        }
    }
}

/***/

.error {
    display: flex;

    &__main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h1 {
            margin-bottom: pxToRem(24);
        }
    }

    &-msg {
        padding-left: pxToRem(36);
    }

    &__button {
        margin-top: pxToRem(36);

        span {
            white-space: initial;
        }
    }
}

@include media-breakpoint-down(md) {
    .error {
        padding: pxToRem(36) 0;

        &__img {
            margin: 0 auto pxToRem(48);
        }

        &-msg {
            padding-left: initial;
        }

        &__button {
            display: flex;
        }
    }
}