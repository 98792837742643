

$button-font-size: $base-font-size !default;
$button-height: ($button-font-size*2.5) !default;
$button-ratio: 0.3 !default;

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    //justify-content: center;
    min-width: 0;
    white-space: nowrap;
}

.button.button--block {
    width: 100%;
}

.button:not(.button--link) {
    //padding: 0 $button-height * $button-ratio;
    //height: $button-height;
    //background-color: $color-gray-10;
    //font-size: $button-font-size;
    //border: 1px solid $color-gray-10;
    //border-radius: $border-radius;
    //transition: 0.15s background, 0.15s border, 0.15s color;

    height: 30px;
    font-size: pxToRem(16);
    line-height: 160%;
    color: $font-color-dark;
    text-decoration: underline;

    &::before {
        content: '';
        width: 30px;
        height: 30px;
        margin-right: 12px;
        background-color: $color-primary;
        background-image: inline-svg('arrow-m', $color-white);
        background-size: 17px 17px;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 50% 50% 4px 50%;
    }
}

.button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}

.button.button--square:not(.button--link) {
    justify-content: center;
    width: $button-height;
    height: 100%;
    padding: 0;
    text-align: center;
    text-decoration: none;

    &::before {
        content: none;
    }
}

.button:not(.button--link):hover,
.button:not(.button--link):focus {
    //background: $color-gray-15;
    //border-color: $color-gray-15;
}

.button.button--small:not(.button--link) {
    //font-size: $button-font-size * 0.875;
    //padding: 0 $button-height * $button-ratio * 0.875;
    //height: $button-height * 0.875;
    height: 30px;
    font-size: pxToRem(15);
    line-height: 120%;

    &::before {
        width: 20px;
        height: 20px;
        background-image: inline-svg('arrow-s', $color-white);
        background-size: 11px 11px;
        transition: .4s ease;
    }

    &:hover::before {
        margin-right: 16px;
        background-color: $color-primary-hover;
        border-radius: 50% 50% 50% 2px;
    }
}

.button.button--small.button--square:not(.button--link) {
    width: $button-height * 0.875;
    padding: 0;
}

.button.button--large:not(.button--link) {
    //font-size: $button-font-size * 1.125;
    //padding: 0 $button-height * $button-ratio * 1.125;
    //height: $button-height * 1.125;
    height: 50px;
    font-size: pxToRem(20);
    line-height: 120%;

    &::before {
        width: 50px;
        height: 50px;
        background-image: inline-svg('arrow-m', $color-white, transparent);
        background-size: 11px 11px;
        transition: .4s ease;
    }

    &::after {
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
        border: 1px solid $color-white;
        border-radius: 50% 50% 0 50%;
        transition: .4s ease;
    }

    &:hover::before {
        //margin-right: 16px;
        background-color: $color-primary-hover;
        border-radius: 50% 50% 50% 4px;
    }

    &:hover::after {
        border-radius: 50% 50% 50% 0;
    }
}

.button.button--large.button--square:not(.button--link) {
    width: $button-height * 1.125;
    padding: 0;
}

.button.button--xl:not(.button--link), .button.button--submit:not(.button--link) {
    height: 68px;
    padding: 0 70px 0 32px;
    font-size: pxToRem(22);
    line-height: 120%;
    font-weight: 500;
    color: $font-color-light;
    text-decoration: none;
    background-color: $color-primary;
    border-radius: 34px 34px 4px 34px;
    transition: .4s ease;
    &::before {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 36px;
        height: 36px;
        background-image: inline-svg('arrow-l', $color-white, transparent);
        background-size: 22px 22px;
        border: 1px solid $color-white;
        transition: .4s ease;
    }
    &:hover {
        padding-right: 75px;
        background-color: $color-primary-hover;
    }

    &:hover::before {
        background-image: inline-svg('arrow-l', $color-black, transparent);
        background-color: $color-white;
        border-radius: 34px 34px 34px 4px;
    }
}

.button.button--special:not(.button--link) {
    height: 40px;
    padding: 10px 48px 10px 16px;
    font-size: pxToRem(17);
    line-height: 120%;
    font-weight: 400;
    color: $font-color-light;
    background-color: $color-primary;
    border-radius: 34px 34px 4px 34px;
    cursor: pointer;

    &::before {
        position: absolute;
        top: initial;
        right: 10px;
        width: 23px;
        height: 23px;
        margin-right: initial;
        background-image: inline-svg('arrow-s', $color-white);
        background-size: 11px 11px;
        border-radius: 50% 50% 0 50%;
        border: 1px solid $color-white;
    }
}

/**
 * Link
 */

.button.button--link {
    font-size: $button-font-size;
    color: $color-primary;
    transition: 0.15s color;
}

.button.button--link:hover,
.button.button--link:focus {
    color: $color-primary-hover;
}

.button.button--link.button--primary {
    color: $color-primary;
}

.button.button--link.button--primary:hover,
.button.button--link.button--primary:focus {
    color: $color-primary-hover;
}

.button.button--link.button--secondary {
    color: $color-secondary;
}

.button.button--link.button--secondary:hover,
.button.button--link.button--secondary:focus {
    color: $color-secondary-hover;
}


.button.button--link.button--success {
    color: $color-success;
}

.button.button--link.button--success:hover,
.button.button--link.button--success:focus {
    color: $color-success-darker;
}

.button.button--link.button--warning {
    color: $color-warning;
}

.button.button--link.button--warning:hover,
.button.button--link.button--warning:focus {
    color: $color-warning-darker;
}

.button.button--link.button--danger {
    color: $color-danger;
}

.button.button--link.button--danger:hover,
.button.button--link.button--danger:focus {
    color: $color-danger-darker;
}

.button.button--link.button--info {
    color: $color-info;
}

.button.button--link.button--info:hover,
.button.button--link.button--info:focus {
    color: $color-info-darker;
}

.button.button--small.button--link {
    font-size: $button-font-size * 0.875;
}

.button.button--large.button--link {
    font-size: $button-font-size * 1.125;
}

.button.button--link[disabled],
.button.button--link[disabled]:hover,
.button.button--link[disabled]:focus {
    color: $color-gray-40;
}

/**
 * Button primary
 */

.button.button--primary:not(.button--link) {
    @include dynamic-color($color-primary);
    border-color: $color-primary;
}

.button.button--primary:not(.button--link):hover,
.button.button--primary:not(.button--link):focus {
    @include dynamic-color($color-primary-hover);
    border-color: $color-primary-hover;
}

.button.button--primary:not(.button--link):active {
    @include dynamic-color($color-primary-dark);
    border-color: $color-primary-dark;
}

.button.button--primary:not(.button--link)[disabled],
.button.button--primary:not(.button--link)[disabled]:hover,
.button.button--primary:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-primary);
    border-color: $color-primary;
}

/**
 * Button secondary
 */

.button.button--secondary:not(.button--link) {
    @include dynamic-color($color-secondary);
    border-color: $color-secondary;
}

.button.button--secondary:not(.button--link):hover,
.button.button--secondary:not(.button--link):focus {
    @include dynamic-color($color-secondary-hover);
    border-color: $color-secondary-hover;
}

.button.button--secondary:not(.button--link):active {
    @include dynamic-color($color-secondary-dark);
    border-color: $color-secondary-dark;
}

.button.button--secondary:not(.button--link)[disabled],
.button.button--secondary:not(.button--link)[disabled]:hover,
.button.button--secondary:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-secondary);
    border-color: $color-secondary;
}

/**
 * Button success
 */

.button.button--success:not(.button--link) {
    @include dynamic-color($color-success);
    border-color: $color-success;
}

.button.button--success:not(.button--link):hover,
.button.button--success:not(.button--link):focus {
    @include dynamic-color($color-success-light);
    border-color: $color-success-light;
}

.button.button--success:not(.button--link):active {
    @include dynamic-color($color-success-dark);
    border-color: $color-success-dark;
}

.button.button--success:not(.button--link)[disabled],
.button.button--success:not(.button--link)[disabled]:hover,
.button.button--success:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-success-lighter);
    border-color: $color-success-lighter;
}

/**
 * Button warning
 */

.button.button--warning:not(.button--link) {
    @include dynamic-color($color-warning);
    border-color: $color-warning;
}

.button.button--warning:not(.button--link):hover,
.button.button--warning:not(.button--link):focus {
    @include dynamic-color($color-warning-light);
    border-color: $color-warning-light;
}

.button.button--warning:not(.button--link):active {
    @include dynamic-color($color-warning-dark);
    border-color: $color-warning-dark;
}

.button.button--warning:not(.button--link)[disabled],
.button.button--warning:not(.button--link)[disabled]:hover,
.button.button--warning:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-warning-lighter);
    border-color: $color-warning-lighter;
}

/**
 * Button danger
 */

.button.button--danger:not(.button--link) {
    @include dynamic-color($color-danger);
    border-color: $color-danger;
}

.button.button--danger:not(.button--link):hover,
.button.button--danger:not(.button--link):focus {
    @include dynamic-color($color-danger-light);
    border-color: $color-danger-light;
}

.button.button--danger:not(.button--link):active {
    @include dynamic-color($color-danger-dark);
    border-color: $color-danger-dark;
}

.button.button--danger:not(.button--link)[disabled],
.button.button--danger:not(.button--link)[disabled]:hover,
.button.button--danger:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-danger-lighter);
    border-color: $color-danger-lighter;
}

/**
 * Button info
 */

.button.button--info:not(.button--link) {
    @include dynamic-color($color-info);
    border-color: $color-info;
}

.button.button--info:not(.button--link):hover,
.button.button--info:not(.button--link):focus {
    @include dynamic-color($color-info-light);
    border-color: $color-info-light;
}

.button.button--info:not(.button--link):active {
    @include dynamic-color($color-info-dark);
    border-color: $color-info-dark;
}

.button.button--info:not(.button--link)[disabled],
.button.button--info:not(.button--link)[disabled]:hover,
.button.button--info:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-info-lighter);
    border-color: $color-info-lighter;
}

/**
 * Button group
 */

.button-group {
    display: flex;
    flex-direction: row;
}

.button-group > .button,
.button-group > .input {
    position: relative;
    border-radius: 0;
    margin-left: -1px;
}

.button-group > .input {
    flex: 1 1 auto;
}

.button-group > .button {
    flex: 0 0 auto;
}

.button-group > .button:hover,
.button-group > .input:hover {
    z-index: 10;
}

.button-group > .button:first-child,
.button-group > .input:first-child {
    border-radius: $border-radius 0 0 $border-radius;
    margin-left: 0;
}

.button-group > .button:last-child,
.button-group > .input:last-child {
    border-radius: 0 $border-radius $border-radius 0;
}
