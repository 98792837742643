/*
  * Use only local fonts!!
  * Preload only woff2 in src/Templates/element/html/head.php
  * Preloader Example: <link rel="preload" href="/src/theme/default/font/***.woff2" as="font" type="font/woff2" crossorigin>
  * IMPORTANT!!!! All webfonts must be converted with https://transfonter.org/ and the option "Fix vertical metrics"
*/


@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompBlackItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompExtraboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompExtraboldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Bold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompLightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompBold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompExtrabold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompExtrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-BlackItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompBoldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-BoldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompExtralight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompExtralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp Exlight';
    src: url('/src/theme/default/font/HDColton-CompExtralightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompExtralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Black.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompBlack.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompLight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompMedium.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompMediumItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondMedium.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondLight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondBlack.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond Exlight';
    src: url('/src/theme/default/font/HDColton-CondExtralightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondExtralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondBold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompSemiboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompSemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondExtraboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondExtraboldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondLightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompRegular.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondExtralight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondExtralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompThinItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondBlackItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompThin.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondExtrabold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondExtrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondBoldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompSemibold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompSemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Comp';
    src: url('/src/theme/default/font/HDColton-CompRegularItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CompRegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondMediumItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondRegular.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondRegularItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondRegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Light.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondSemiboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondSemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Thin.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-ExtraboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-ExtraboldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Extralight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Variable';
    src: url('/src/theme/default/font/HDColton-Variable.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Variable.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-LightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-MediumItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondSemibold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondSemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-ThinItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideBold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideExtrabold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideExtrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-SemiboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Extrabold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Extrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-ExtralightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-ExtralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Medium.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondThinItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-RegularItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Cond';
    src: url('/src/theme/default/font/HDColton-CondThin.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-CondThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideBlack.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Regular.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideBoldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton';
    src: url('/src/theme/default/font/HDColton-Semibold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideBlackItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideExtraboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideExtraboldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideExtralight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideExtralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide Exlight';
    src: url('/src/theme/default/font/HDColton-WideExtralightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideExtralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideLightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideLight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideSemibold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideSemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideSemiboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideSemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideRegularItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideRegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideMedium.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideThin.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideRegular.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideMediumItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton Wide';
    src: url('/src/theme/default/font/HDColton-WideThinItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-WideThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideBlack.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideBold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide Exbold';
    src: url('/src/theme/default/font/HDColton-XWideExtraboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideExtraboldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideLight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideExtralight.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideExtralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideSemibold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideSemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideThinItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideBoldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideSemiboldItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideSemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideMedium.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideMediumItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideThin.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideLightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideExtrabold.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideExtrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideRegularItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideRegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide Exlight';
    src: url('/src/theme/default/font/HDColton-XWideExtralightItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideExtralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideRegular.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HD Colton XWide';
    src: url('/src/theme/default/font/HDColton-XWideBlackItalic.woff2') format('woff2'),
    url('/src/theme/default/font/HDColton-XWideBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}


