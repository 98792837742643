.gallery {
    margin: $spacing-xl 0;
}

.item-galerie {
    position: relative;
    padding: 0 14px !important;
    &:first-child .image::after, &:nth-child(4n + 4) .image::after, &:nth-child(4n + 1) .image::after {
        padding-bottom: 115% !important;
    }
}

.item-galerie {
    &:nth-child(n+5) {
        max-height: 0;
        overflow: hidden;
        transition: all .8s ease;
        transition-delay: .2s;
    }
}

.item-galerie .gallery__item {
    &:not(.is-threesixty)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        width: 70px;
        height: 70px;
        background-color: rgba($color-black, .2);
        background-image: inline-svg('maximize', $color-white);
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        opacity: 0;
        transition: opacity .4s ease;
        pointer-events: none;
    }

    &:hover::after {
        opacity: 1;
    }
}

.item-galerie .is-threesixty::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: 70px;
    height: 70px;
    background-color: rgba($color-black, .2);
    background-image: inline-svg('threesixty', $color-white);
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    opacity: 1;
    transition: opacity .4s ease;
    pointer-events: none;
}

.gallery__item {
    display: block;
    margin: 14px 0 !important;

    .image {
        overflow: hidden;
    }

    img {
        transition: all .4s ease !important;
    }

    &:hover img {
        transform: scale(1.03);
    }
}

.js-show-more {
    .item-galerie {
        &:nth-child(n+5) {
            max-height: 900px;
        }
    }

    + .items-galerie__more-button {
        display: none;
    }
}

.items-galerie__more-button {
    display: flex;
    justify-content: center;
    margin-top: $spacing-m;
}

.js-btn-show-more-galerie {
    &::before {
        border-radius: 50% 0 50% 50% !important;
        transform: rotate(90deg) !important;
    }
}

.no-panorama {
    .item-galerie:first-child::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        width: 70px;
        height: 70px;
        background-color: rgba($color-black, .2);
        background-image: inline-svg('maximize', $color-white);
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        opacity: 0;
        transition: opacity .4s ease;
        pointer-events: none;
    }
    .item-galerie:hover::after {
        opacity: 1;
    }
}

@include media-breakpoint-down(sm) {
    .gallery {
        margin: $spacing-mobile 0;
    }

    .items-galerie {
        margin: 0 -14px;
    }

    .item-galerie {
        padding: 0 !important;
    }
}