.faq {
    margin-bottom: $spacing-xl;
}

.faq__item--container:not(:first-child) {
    margin-top: 12px;
}

.faq__item .faq__header {
    cursor: pointer;
    position: relative;
    padding: 17px 56px 17px 20px;
    background-color: $color-white;
    border-radius: 28px 28px 0 24px;
    transition: border-radius .3s;
}

.faq__item .faq__header::before {
    content: '';
    position: absolute;
    top: calc(50% - 20px);
    right: 8px;
    width: 40px;
    height: 40px;
    background-color: $color-primary;
    border-radius: 20px 20px 2px 20px;
}

.faq__item .faq__header:after {
    content: '\00a0';
    position: absolute;
    top: calc(50% - 20px);
    right: 8px;
    display: block;
    width: 40px;
    height: 40px;
    transform: rotate(90deg);
    background-size: 16px 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('arrow-l', $color-white);

    transition: transform 0.3s;
}

.faq .uk-open .faq__item .faq__header:after {
    transform: rotate(270deg);
}

.faq__item .article__content > :last-child {
    margin-bottom: 0;
}

.faq__item .faq__body {
    z-index: 5;
    position: relative;
}

.faq__item .faq__image {
    z-index: 10;
    position: relative;
}

.faq__item--container.uk-open .faq__header {
    border-radius: 28px 28px 0 0;
}

.faq .faq__content {
    margin-top: 2px;
    padding: 24px 20px 36px;
    background-color: $color-white;
    border-radius: 0 0 0 24px;
}

.faq__content .facts__outer {
    flex-wrap: wrap;

    .facts {
        flex-basis: calc(33% - 20px);

        &:not(:first-child) {
            margin-left: initial;
            margin-bottom: 12px;
        }
    }
}

.multipage__item .faq {
    margin-top: $spacing-m;
}

.faq__body .article--content > table {
    margin-top: 0;
}

.faq__content table {
    position: relative;
    background: linear-gradient(270deg,
            white 30%,
            rgba(255, 255, 255, 0)
    ) right center,
    radial-gradient(
                    farthest-side at 100% 50%,
                    rgba(0, 0, 0, 0.1),
                    rgba(0, 0, 0, 0.0)
    ) right center;
    background-repeat: no-repeat;
    background-size: 110px 100%, 70px 200%;
    background-attachment: local, scroll;
}

@include media-breakpoint-down(lg) {
    .faq__content .facts__outer .facts {
        flex-basis: calc(50% - 20px);
    }
}

@include media-breakpoint-down(sm) {
    .faq__content .facts__outer .facts {
        flex-basis: 100%;

        &:first-child {
            margin-bottom: 12px;
        }
    }

    .faq__content table {
        position: relative;
        padding-bottom: 4px;
        background: linear-gradient(270deg,
                white 30%,
                rgba(255, 255, 255, 0)
        ) right center,
        radial-gradient(
                        farthest-side at 100% 50%,
                        rgba(0, 0, 0, 0.08),
                        rgba(0, 0, 0, 0.0)
        ) right center;
        background-repeat: no-repeat;
        background-size: 110px 100%, 50px 200%;
        background-attachment: local, scroll;
    }

    .wischicon::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100%;
        background-image: url('/src/theme/default/icon/hand-scroll.svg');
        background-size: 34px 34px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: calc(100% - 5px) 5px;
        background-attachment: scroll;
    }

    .wischicon::before {
        content: '';
        position: absolute;
        top: 5px;
        right: 5px;
        width: 38px;
        height: 38px;
        background-color: $color-white;
        border-radius: 50% 50% 50% 50%;
        box-shadow: 0 2px 12px rgba($color-black, .2);
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .3);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
}