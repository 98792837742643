.faqteaser__outer {
    margin-top: 86px;
}

.faqteaser__img {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $color-white;
    box-shadow: 0 2px 12px rgba($color-black, .3);
}

.item-faq__storage {
    display: none;
}

.item-faq__content {
    display: none;
}

.item-faq__content {
    margin-bottom: $spacing-l;
}

.item-faq__content-outer {
    background: #fff;
    color: $font-color;
    padding: $spacing-m;
    position: relative;
    box-shadow: 0 2px 12px rgba($color-black, .3);

    //&::before {
    //    content: '';
    //    width: 0;
    //    height: 0;
    //    border-style: solid;
    //    border-width: 0 15px 12px;
    //    border-color: transparent transparent $color-primary transparent;
    //    position: absolute;
    //    top: -12px;
    //    left: 24%;
    //    transform: translateX(-50%);
    //}
}

.item-faq__content_inner {
    display: flex;

    .image {
        margin-right: $spacing-m;
    }
}

.faq__content {
    display: flex;
    flex-direction: column;
}

.faq-content__content {
    margin-left: $spacing-s;
    font-weight: 300;
}

.item-faq--big {
    height: 100%;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -64px;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 12px;
        border-color: transparent transparent $color-primary transparent;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity .2s ease;
    }

    &.show-arrow::after {
        opacity: 1;
    }
}

.item-faq--big__content {
    flex-direction: row;
    align-items: center;
    height: 100%;
    background-color: $color-white;
    padding: 24px 16px;

    h3 {
        margin: initial;
    }
}

.faq-teaser__item-item {
    margin-bottom: $spacing-l;

    &:hover {
        cursor: pointer;
    }
}

.faq__content--image {
    margin-right: $spacing-m;
}

.faq-teaser__item-item .teaser-small__hl {
    position: relative;
    padding-right: 30px;

    &::after {
        content: '';
        position: absolute;
        right: -5px;
        top: 60%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-image: inline-svg('angle-down', $color-primary);
        background-repeat: no-repeat;
    }
}

.faqteaser__outer .faq__content .downloads {
    padding-top: pxToRem(28);
    margin-left: pxToRem(36);
    margin-top: auto;

    h4 {
        margin-bottom: pxToRem(28);
    }

    ul.downloads__items > li {
        flex: initial;
    }
}

@include media-breakpoint-down(lg) {
    .item-faq__content_inner {
        flex-direction: column-reverse;

        .image {
            margin-top: $spacing-m;
        }
    }
}

@include media-breakpoint-down(sm) {
    .faq-teaser__item-item {
        margin-bottom: initial;
    }

    .faq-teaser__item-item:not(:first-child) {
        margin-top: $spacing-l;
    }

    .item-faq__content {
        margin-top: $spacing-m;
        margin-bottom: initial;
    }

    .item-faq__content-outer {
        padding: $spacing-m 14px $spacing-m;
    }

    .faq-content__topline, .faq-content__headline {
        padding-left: 10px;
    }

    .faqteaser__outer {
        margin-top: $spacing-l;
    }

    .item-faq--big::after {
        bottom: -48px;
    }
}