.facts__outer {
    display: flex;
    margin: 7px 0 14px;
    color: $font-color-dark;
}

.facts {
    display: flex;
    align-items: center;

    span {
        hyphens: auto;
    }
}

.facts:not(:last-child) {
    margin-right: 20px;
}

.icon--medium {
    display: inline;
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

.teaser__facts .icon--medium {
    display: inline;
    width: 34px;
    height: 34px;
    margin-right: 8px;
}

@include media-breakpoint-down(xl) {
    .facts__outer {
        flex-wrap: wrap;
    }

    .facts {
        margin-bottom: 8px;
    }
}

@include media-breakpoint-down(sm) {
    .facts__outer {
        flex-wrap: wrap;
    }

    .facts {
        flex: 1 1 calc(50% - 12px);

        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}