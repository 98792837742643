.swiper-wrapper {
    width: auto;
    min-width: 100%
}

.grid .swiper-slide {
    height: auto;
}

/* for fullwidth swiper*/
.swiper, .swiper-fullwidth {
    overflow: visible !important;
}