.faq-contentmodule {
    margin-top: $spacing-l;
}

.faq-contentmodule__item {
    display: flex;

    &:not(:last-child) {
        margin-bottom: $spacing-m;
    }
}

.faq-contentmodule__img {
    flex: 1 1 30%;
}

.faq-contentmodule__content {
    flex: 1 1 60%;
    margin-left: $spacing-xs;
}

.faq-content__topline {
    margin-bottom: $spacing-xs;
    font-size: pxToRem(17);
    line-height: 120%;
    color: $font-color-dark;
}

.faq-content__headline {
    margin-bottom: $spacing-s;
}

.faq-content {
    margin-left: $spacing-s;
}

@include media-breakpoint-down(md) {
    .faq-contentmodule {
        margin-top: $spacing-m;
    }

    .faq-contentmodule__item {
        flex-direction: column;
    }

    .faq-contentmodule__img {
        margin-bottom: $spacing-xs;
    }

    .faq-contentmodule__item .faq-contentmodule__img {
        margin-left: -14px;
        margin-right: -14px;
    }

    .faq-contentmodule__content {
        margin-left: initial;

        .faq-content__topline {
            font-weight: 500;
        }

        .faq-content {
            margin-left: initial;
        }

    }
}