
@import "../icon/sass-inline-svg";

$chosen-size: $input-size !default;
$chosen-height: ($input-height - 1px) !default;

.chosen-container {
    position: relative;
    display: block;
    width: 100%;
}

.chosen-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0 ;
}

.chosen-pseudo,
.chosen-container .chosen-single,
.chosen-container .chosen-choices {
    cursor: default;
    appearance: none;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 (($chosen-height * 0.3) + $chosen-size) 0 $chosen-height * 0.3;
    min-height: $chosen-height;
    font-size: $chosen-size;
    color: $color-black;
    border: 1px solid $color-gray-15;
    border-radius: $border-radius;
    transition: 0.15s border;
    background-color: $color-white;
    background-size: $chosen-size*0.9 $chosen-size*0.9;
    background-position: calc(100% - #{$chosen-height * 0.3}) center;
    background-repeat: no-repeat;
    background-image: inline-svg('angle-down', $color-black);
}

.chosen-pseudo:hover,
.chosen-pseudo:focus,
.chosen-container .chosen-single:hover,
.chosen-container .chosen-choices:hover,
.chosen-container.chosen-with-drop .chosen-single,
.chosen-container.chosen-container-active .chosen-single,
.chosen-container.chosen-with-drop .chosen-choices,
.chosen-container.chosen-container-active .chosen-choices {
    text-decoration: none;
    border: 1px solid $color-primary;
}

.chosen-container.chosen-with-drop.chosen-container-active .chosen-single,
.chosen-container.chosen-with-drop.chosen-container-active .chosen-choices {
    background-image: inline-svg('angle-up', $color-black);
    border-radius: $border-radius $border-radius 0 0;
}

.chosen-container .chosen-single > span {
    order: 50;
    flex: 1 1 auto;
}

.chosen-container .chosen-single.chosen-default > span,
.chosen-container.chosen-with-drop.chosen-container-active .chosen-single > span {
    color: $color-gray-70
}

.chosen-container .chosen-single .search-choice-close {
    cursor: pointer;
    order: 100;
}

.chosen-container .chosen-single .search-choice-close:after {
    content: '\00a0';
    display: block;
    width: $base-font-size*0.7;
    height: $base-font-size*0.7;
    margin-left: auto;
    background-size: $chosen-size*0.7 $chosen-size*0.7;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('times', $color-danger);
}

.chosen-container .chosen-choices .search-choice {
    background: rgba($color-primary, 0.15);
}

.chosen-container .chosen-choices li:not(:last-child) {
    margin-right: 5px;
}

.chosen-container .chosen-choices li.search-choice {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: $chosen-height * 0.05 $chosen-height * 0.25;
    font-size: $chosen-size * 0.875;
    margin-top: $chosen-height * 0.1;
    margin-bottom: $chosen-height * 0.1;
}

.chosen-container .chosen-choices li.search-choice .search-choice-close {
    cursor: pointer;
    margin-left: 5px;
    margin-right: ($chosen-height * -0.3) + 5;
    border-radius: $border-radius * 0.75;
    transition: background 0.1s;
}

.chosen-container .chosen-choices li.search-choice .search-choice-close:hover {
    background: rgba($color-danger, 0.3);
}

.chosen-container .chosen-choices li.search-choice .search-choice-close:after {
    content: '\00a0';
    display: block;
    width: $base-font-size;
    height: $base-font-size;
    background-size: $base-font-size $base-font-size;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('times', $color-black);
}

.chosen-container.chosen-container-single-nosearch .chosen-search {
    display: none;
}

.chosen-container .chosen-choices li.search-field .chosen-search-input {
    cursor: default;
    font-size: $chosen-size;
    border: 0;
}

.chosen-container .chosen-choices li.search-field .chosen-search-input.default {
    color: $color-gray-50;
}

.chosen-container .chosen-drop {
    z-index: 150;
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    display: block;
    width: 100%;
    background: $color-white;
    border: 1px solid $color-primary;
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;
}

.chosen-container:not(.chosen-with-drop) .chosen-drop {
    display: none !important;
}

.chosen-container .chosen-drop .chosen-search {
    border-top: 1px solid $color-gray-10;
}

.chosen-container .chosen-drop .chosen-search-input {
    display: block;
    width: 100%;
    height: $chosen-height * 0.95;
    padding: 0 $chosen-height * 0.3;
    font-size: $chosen-size;
    border: 0;
    background: $color-white;
}

.chosen-container .chosen-drop .chosen-results {
    max-height: 240px;
    overflow-y: auto;
    border-top: 1px solid $color-gray-10;
}

.chosen-container .chosen-drop .chosen-results li {
    cursor: default;
    padding: $chosen-height * 0.075 $chosen-height * 0.3;
    line-height: $chosen-height * 0.825;
    font-size: $chosen-size;
    transition: background 0.15s;
}

.chosen-container .chosen-drop .chosen-results li.active-result {
    cursor: pointer;
}

.chosen-container .chosen-drop .chosen-results li.active-result:hover {
    background: $color-gray-05;
}

.chosen-container .chosen-drop .chosen-results li.result-selected {
    background: rgba($color-primary, 0.15);
}

.chosen-container .chosen-drop .chosen-results li.result-disabled {
    color: $color-gray-50;
    background: $color-gray-05;
}
