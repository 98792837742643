[data-srcset] {
    opacity: 0;
}

[data-srcset].lazyloaded {
    opacity: 1;
    transition: opacity 0.5s;
}

.image {
    position: relative;
    width: 100%;
    height: auto;
}

.image.image--background {
    position: absolute;
    background-position: center;
    background-size: cover;
}

.image img {
    z-index: 3;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
}

.image:not(.empty):before {
    content: '\00a0';
    z-index: 1;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    display: block;
    width: 30px;
    height: 30px;
    border: 2px solid $color-gray-30;
    border-top-color: transparent;
    border-radius: 500px;
    animation: SxSpinner 0.85s linear infinite;
}
