.asp {
    margin: $spacing-xl 0;
}

.asp__item {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    background-color: $color-secondary;
    border-radius: 130px 130px 8px 130px;
}

.asp__image {
    margin-right: $spacing-m;
}

.asp__image .image {
    width: 220px;
    height: 220px;
    overflow: hidden;
    border-radius: 110px 110px 0 110px;
    box-shadow: 0 2px 12px rgba($color-black, .3);

    img {
        border-radius: 110px 110px 0 110px;
    }
}

.asp__body {
    color: $font-color-light;
    p {
        color: $font-color-light;
    }
}

.asp__headline {
    margin: 16px 0;
    color: $font-color-light;
}

.asp--content {
    margin-left: $spacing-s;
    font-weight: 300;
}

.asp__contact {
    margin-top: 8px;
    margin-left: $spacing-s;
    a {
        font-size: pxToRem(26);
        line-height: 120%;
        font-weight: 500;
        color: $font-color-light;
    }

    span {
        font-size: pxToRem(26);
        line-height: 120%;
        font-weight: 500;
        padding: 0 10px;
    }
}

.asp__socials {
    display: inline-flex;
    margin-top: $spacing-xs;
    a {
        display: inline-block;
        width: 40px;
        height: 40px;

        &:not(:first-child) {
            margin-left: 16px;
        }
    }
}

.asp__ctas {
    display: inline-flex;
    margin-left: $spacing-l;
    a {
        min-width: 135px;
        color: $font-color-light !important;
        text-decoration: underline !important;

        &:not(:last-child) {
            margin-right: $spacing-s;
        }
    }
}

@media (max-width: 1050px) {
    .asp__item {
        justify-content: center;
        max-width: 347px;
        padding-bottom: 33px;
        margin: auto;
        border-radius: 174px 174px 8px 8px;
    }

    .asp__image {
        margin-right: initial;
        width: 100%;
        height: auto;
    }

    .asp__image .image {
        width: 100%;
        height: auto;
        border-radius: 153px 153px 4px 153px;
        img {
            border-radius: 153px 153px 4px 153px;
        }
    }

    .asp__headline {
        margin-top: $spacing-xs;
    }

    .asp__content {
        font-weight: 300;
    }

    .asp__contact {
        margin-left: initial;
    }

    .asp__ctas {
        flex-wrap: wrap;
        margin-top: $spacing-xs;
        margin-left: initial;
    }
}

@include media-breakpoint-down(sm) {
    .asp {
        margin: $spacing-mobile 0;
    }
}