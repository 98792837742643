@function reverse($list) {
    $result: ();

    @if length($list) == 0 {
        @return $result;
    }

    @for $i from length($list) * -1 through -1 {
        $result: append($result, nth($list, abs($i)));
    }

    @return $result;
}

@function strip($number) {
    @return $number / ($number * 0 + 1);
}

@function pxToRem($value) {
    $remValue: ($value / 16) + rem;
    @return $remValue;
}


@function dynamic-color-text($bg) {
    @if(lightness($bg) > 50) {
        @return $color-dark;
    } @else {
        @return $color-light;
    }
}

@mixin dynamic-color($color) { // dc is short for dynamic color
    background: $color;
    color: dynamic-color-text($color);
}
