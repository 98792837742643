
@import "../icon/sass-inline-svg";

$input-size: $base-font-size !default;
$input-height: $input-size*2.5 !default;

.input {
    display: block;
    padding: 0 $input-height * 0.3;
    height: $input-height;
    background-color: $color-white;
    font-size: $input-size;
    color: $color-black;
    border: 1px solid $color-gray-15;
    border-radius: $border-radius;
    transition: 0.15s border;
}

.input::placeholder,
.input:-ms-input-placeholder,
.input::-ms-input-placeholder {
    color: $color-gray-70;
}

.input.input--block {
    width: 100%;
}

.input:hover,
.input:focus {
    border: 1px solid $color-primary;
}

.input.input--small {
    font-size: $input-size * 0.875;
    padding: 0 $input-height * 0.3 * 0.875;
    height: $input-height * 0.875;
}

.input.input--large {
    font-size: $input-size * 1.125;
    padding: 0 $input-height * 0.3 * 1.125;
    height: $input-height * 1.125;
}

.input[disabled] {
    cursor: not-allowed;
    background: $color-gray-05;
}

.input[disabled]:hover,
.input[disabled]:focus {
    border-color: $color-gray-15;
}

/**
 * Input group
 */

.input-group {
    position: relative;
    display: flex;
    flex-direction: row;
}

.input-group > .button,
.input-group > .input-group-append,
.input-group > .input-group-prepend,
.input-group > .input,
.input-group > .input-group-append,
.input-group > .input-group-prepend {
    position: relative;
    margin-left: -1px;
}

.input-group > .button,
.input-group > .input-group-append,
.input-group > .input-group-prepend {
    z-index: 5;
}

.input-group > .button,
.input-group > .input-group-append > .button,
.input-group > .input-group-prepend > .button {
    margin: -1px !important;
}

.input-group > .button,
.input-group > .input-group-append > .button,
.input-group > .input-group-prepend > .button,
.input-group > .input,
.input-group > .input-group-append > .input,
.input-group > .input-group-prepend > .input {
    border-radius: 0;
}

.input-group > .button:hover,
.input-group > .input-group-append > .button:hover,
.input-group > .input-group-prepend > .button:hover {
    z-index: 10;
}

.input-group > .button:first-child,
.input-group > .input-group-append:first-child > .button,
.input-group > .input-group-prepend:first-child > .button,
.input-group > .input:first-child,
.input-group > .input-group-append:first-child > .input,
.input-group > .input-group-prepend:first-child > .input {
    margin-left: 0;
}

.input-group > .button:first-child,
.input-group > .input-group-append:first-child > .button,
.input-group > .input-group-prepend:first-child > .button,
.input-group > .input:first-child,
.input-group > .input-group-append:first-child > .input,
.input-group > .input-group-prepend:first-child > .input {
    border-radius: $border-radius 0 0 $border-radius;
}

.input-group > .button:last-child,
.input-group > .input-group-append:last-child > .button,
.input-group > .input-group-prepend:last-child > .button,
.input-group > .input:last-child,
.input-group > .input-group-append:last-child > .input,
.input-group > .input-group-prepend:last-child > .input {
    border-radius: 0 $border-radius $border-radius 0;
}

.input-group > .button:first-child:last-child,
.input-group > .input-group-append:first-child:last-child > .button,
.input-group > .input-group-prepend:first-child:last-child > .button,
.input-group > .input:first-child:last-child,
.input-group > .input-group-append:first-child:last-child > .input,
.input-group > .input-group-prepend:first-child:last-child > .input {
    border-radius: $border-radius;
}

.input-group > .input-group-prepend,
.input-group > .input-group-append {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-gray-80;
    background-color: $color-gray-15;
    border-radius: $border-radius;
}

.input-group > .input-group-append > *,
.input-group > .input-group-prepend > * {
    order: 50;
}

.input-group  .input-group-text {
    padding: 0 12px;
}

.input-group > .input-group-prepend .input-group-text {
    order: 100;
}

.input-group > .input-group-append .input-group-text {
    order: 0;
}

/**
 * Extras
 */

.formular-item.formular-item--date .input,
.formular-item.formular-item--datetime .input{
    padding-right: ($input-height * 0.3) + 14;
    background-size: 12px 12px;
    background-position: calc(100% - #{$input-height * 0.3}) 50%;
    background-repeat: no-repeat;
    background-image: inline-svg('calendar', $color-black);
}

.formular-item.formular-item--time .input {
    padding-right: ($input-height * 0.3) + 14;
    background-size: 12px 12px;
    background-position: calc(100% - #{$input-height * 0.3}) 50%;
    background-repeat: no-repeat;
    background-image: inline-svg('clock', $color-black);
}

