.downloads h3 {
    margin-bottom: $spacing-xs;
}

ul.downloads__items {
    display: flex;
    flex-wrap: wrap;
    padding-left: initial;
}

ul.downloads__items > li {
    padding-left: initial;
    //flex-basis: calc(50% - 28px);
    flex: 1 1 calc(50% - 28px);


    &:not(:last-child) {
        margin: 0 $spacing-xs 16px 0;
    }
}

.downloads__items li::marker, ul.downloads__items li::before {
    content: none;
}

.downloads__items a {
    text-decoration: underline !important;
    white-space: initial;
    &::before {
        display: inline-block;
        flex-shrink: 0;
    }
}


