.vergleich__outer {
    margin: $spacing-l 0 $spacing-xl;
}

.vergleich {
    display: flex;
}


.vergleich__left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.vergleich__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.vergleich__title {
    display: flex;
    align-items: flex-end;
}

.vergleich__title--left {
    .vergleich__img {
        margin-left: $spacing-xs;
        border-radius: 100px 100px 4px 100px;
    }
}

.vergleich__title--right {
    flex-direction: row-reverse;

    .vergleich__heading p {
        text-align: start;
    }

    .vergleich__img {
        margin-right: $spacing-xs;
        border-radius: 100px 100px 100px 4px;
    }
}

.vergleich__heading {
    p {
        margin-bottom: $spacing-xs;
        font-size: pxToRem(17);
        text-align: end;
        color: $font-color-dark;
    }

    h2 {
        font-size: pxToRem(62);
    }
}


.vergleich__img {
    width: 200px;
    height: 200px;
    border: 1px solid $color-white;
    box-shadow: 0 2px 12px rgba($color-black, .3);
}

.vergleich__content {
    margin-top: $spacing-m;

    ul > li {
        position: relative;
        font-size: pxToRem(18);
        line-height: 160%;
        color: $font-color-dark;
    }

    ul > li::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-color: $color-primary;
        background-image: inline-svg('arrow-s', $color-white);
        background-size: 11px 11px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    ul > li:not(:first-child) {
        margin-top: 16px;
    }

    p {
        margin-top: $spacing-xs;
        font-weight: 300;
    }

    .vergleich__content--price {
        font-size: pxToRem(22);
        line-height: 150%;
        font-weight: 500;
    }
}

.vergleich__left {
    .vergleich__content {
        text-align: end;
    }

    ul > li {
        margin-right: $spacing-s;
    }

    ul > li::after {
        right: -36px;
        transform: translateY(-50%) rotate(180deg);
        border-radius: 10px 2px 10px 10px;
    }
}

.vergleich__right {
    ul > li {
        margin-left: $spacing-s;
    }

    ul > li::after {
        left: -36px;
        border-radius: 10px 10px 2px 10px;
    }
}

@include media-breakpoint-down(xl) {
    .vergleich__title {
        flex-direction: column;
        .vergleich__heading {
            margin-bottom: $spacing-s;
        }
    }

    .vergleich__title--right {
        align-items: flex-start;
    }
}

@include media-breakpoint-down(lg) {
    .vergleich {
        flex-wrap: wrap;
    }

    .vergleich__left {
        flex-wrap: wrap;
        align-items: initial;
        margin-bottom: $spacing-s;
    }

    .vergleich__title {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .vergleich__heading {
        margin-top: $spacing-xs;
        p {
            margin-bottom: 16px;
            text-align: initial;
        }

        h2 {
            font-size: pxToRem(56);
        }
    }

    .vergleich__img {
        margin-left: initial !important;
        border-radius: 100px 100px 100px 4px !important;
    }

    .vergleich__content {
        margin-top: $spacing-xs;
        text-align: initial !important;
    }

    .vergleich ul > li {
        margin-left: $spacing-s;
        margin-right: initial;
        &::after {
            left: -36px;
            right: initial;
            transform: translateY(-50%);
            border-radius: 10px 10px 2px 10px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .vergleich__outer {
        margin: $spacing-mobile 0;
    }
}