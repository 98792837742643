.header {
    position: absolute;
    z-index: 50;
    left: 0;
    top: 0;
    width: 100%;
    height: 128px;
}


.header > .container,
.header > .container > .grid {
    height: 100%;
}

@include media-breakpoint-up(lg) {
    .header > .container {
        padding: 0 48px;
    }
}

.header__logo {
    margin: 40px 24px 0 0;
}

.header__logo--scrolled {
    display: none;
    margin-top: 10px;
}

@include media-breakpoint-up(lg) {
    .js-nav-scrolled .header {
        transform: translateY(-100%);
    }
}

.js-nav-scrolled02 .header {
    transform: translateY(0);
    transition: all 1s ease;
    position: fixed;
    height: 80px;
    background-color: $color-white;
}

.js-nav-scrolled02 {
    .header__logo {
        display: none;
    }

    .header__logo--scrolled {
        display: block;
    }
}

.header__language ul {
    width: calc(100% + 4px);
    margin: 0 -2px;
}

.header__language ul > li {
    position: relative;
    display: inline-block;
    padding: 0 8px;
}

.header__language ul > li:not(:last-child):after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
    width: 1px;
    height: 20px;
    background-color: $color-primary;
}

@include media-breakpoint-down(sm) {
    .header {
        height: 60px !important;
    }
}