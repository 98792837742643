.vorteile__outer {
    padding: $spacing-m 0 20px;
    margin: $spacing-xl 0;
    background-color: $color-tertiary;
}

.vorteil {
    display: inline-block;
    font-size: pxToRem(15);
    text-align: center;
    color: $font-color-light;
}