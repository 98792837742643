/*
* Hide & Show Elements
* Fixme @Eddy Mixin machen
*/

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: $sx-breakpoint-xs) {
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: $sx-breakpoint-sm) {
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: $sx-breakpoint-md) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: $sx-breakpoint-lg) {
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}


.last-mb-none > *:last-child,
.last-mb-none > *:last-child > *:last-child,
.last-mb-none > *:last-child > *:last-child > *:last-child,
.last-mb-none > *:last-child > *:last-child > *:last-child > *:last-child,
.last-mb-none > *:last-child > *:last-child > *:last-child > *:last-child > *:last-child,
.last-mb-none > *:last-child > *:last-child > *:last-child > *:last-child > *:last-child > *:last-child,
.last-mb-none > *:last-child > *:last-child > *:last-child > *:last-child > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

pre {
    color: $color-warning !important;
    padding: 25px !important;
    background: $color-black !important;
    border-radius: $border-radius !important;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.posr {
    position: relative;
}

.text--left {
    text-align: left;
}

.text--center {
    text-align: center;
}

.text--right {
    text-align: right;
}

.placeholder  {
    background-color: $color-gray-10;
}


