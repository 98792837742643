
.formular-donut {
    display: none;
}

.formular .grid > [class*=col], .formular .grid > [class^=col] {
    margin-bottom: 36px;
}

.formular.formular--horizontal .formular-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.formular.formular--horizontal .formular-item > * {
    flex: 1 1 auto;
}

.formular.formular--horizontal .formular-item__label {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 4px;
}

@include media('sm') {
    .formular.formular--horizontal .formular-item__label {
        width: 160px;
    }
}

.formular.formular--vertical .formular-item__label {
    margin-bottom: 10px;
}


.formular.formular--horizontal .fomular-item__error {
    flex: 0 0 auto;
    width: 100%;
}

.formular-item {
    margin-bottom: 0;
}

.formular-item__label > span:not(:last-child),
.custom-control-label > span:not(:last-child),
{
    margin-right: 4px;
}

.formular-item__checkbox {
    margin-top: 45px;
}

.formular-item__label + .formular-item__body .formular-item__checkbox {
    margin-top: initial;
}

.formular-item__checkbox .formular-item__required {
    margin-left: 4px;
}

.formular-item__body {
    display: flex;
    flex-direction: column;
}

.formular .formular-item__label {
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: 600;
}

.formular-item__required, .form-error label, {
    color: $color-danger;
}


.formular-item__tooltip {
    font-size: $base-font-size*0.8;
    color: $color-info;
    width: $base-font-size * 0.8;
    display: inline-block;
}

.formular-item__infotext {
    margin-top: 5px;
    font-size: $base-font-size*0.8;
    color: $color-gray-70;
}

.fomular-item__error > .error-message {
    margin-top: 3px;
    display: block;
    color: $color-danger;
    font-size: $base-font-size*0.8;
}

.formular-item__checkbox--single > .checkbox > label,
.formular-item__radio--single > .radio > label {
    min-height: 34px;
}

.form--room, .form--intro, .form--multi {
    margin: $spacing-xl 0;
}

.form--room .container, .form--intro .container, .form--multi .container {
    background-color: $color-white;
    box-shadow: 0 2px 12px rgba($color-black, .3);
    &::before {
        content: "";
        position: absolute;
        top: -12px;
        left: 20%;
        display: inline-block;
        width: 30px;
        height: 12px;
        border-style: solid;
        border-width: 0 15px 12px 15px;
        border-color: transparent transparent $color-white transparent;
    }
}

.form--room form, .form--intro form, .form--multi form {
    margin: $spacing-xl 0 24px;
}

.formular hr {
    width: $spacing-xl;
    height: 1px;
    background-color: $color-secondary;
}

input.formular-item__input {
    height: 60px;
    padding: 0 16px;
}

.formular-item__body .chosen-single.chosen-default {
    height: 60px;
}

.formular-item__body .chosen-choices {
    height: 60px;
}

.formular .button.button--submit:not(.button--link) {
    font-size: pxToRem(18);
    font-weight: 400;
}

.formular__requiredtext {
    text-align: center;
    p {
        font-weight: 600;
    }
}

.formdata-aussenparkplatz .formular-item__checkbox, .formdata-tiefgaragenstellplatz .formular-item__checkbox, .formdata-fruehstueck .formular-item__checkbox, .formdata-aufenthalt-geschaeftlich .formular-item__checkbox, .formdata-aufenthalt-privat .formular-item__checkbox, .formdata-newsletter .formular-item__checkbox, .formdata-privacy .formular-item__checkbox {
    margin-top: initial;
}

.formdata-strasse.col--4-12\@md.col--4-12\@lg {
    padding-right: 2px !important;
}

.formdata-hausnummer.col--2-12\@md.col--2-12\@lg {
    padding-left: 2px !important;
}

.form--default {
    margin-top: $spacing-xl;

    & > .formular > .container {
        background: $color-white;
        padding-top: $spacing-xl;
        padding-bottom: $spacing-xl;
        box-shadow: 0 2px 12px rgba($color-black, .3);
    }
}

.form__show {
    display: block;
}

@include media-breakpoint-down(md) {
    .formular-item__checkbox {
        margin-top: initial;
    }

    .form--default {
        margin-top: $spacing-l;

        & > .formular > .container {
            padding-top: $spacing-l;
            padding-bottom: $spacing-l;
        }
    }
}