.trustyou--onpage {
    position: relative;
    margin: $spacing-m 0 $spacing-xl;
    //padding-top: 48px;
}

.review-wrapper {
    background-color: $color-white;
    & > .container {
        height: 100%;
    }
}

.trustyou-onpage.slider {
    overflow: initial !important;
    margin-top: 0 !important;

    .swiper {
        overflow: hidden !important;
    }
}

.trustyou-onpage {
    .trustyou-reviews__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        transition: opacity .3s ease;
    }

    .swiper-slide-active .trustyou-reviews__item {
        opacity: 1;
        transition: opacity .3s ease;
    }

    .trustyou-reviews__text {
        min-width: 500px;
        transition: all .3s ease;

        & + .button--show-more {
            display: none;
        }
    }

    .trustyou-reviews__text.has-showmore + .button--show-more {
        display: block;
    }

    .trustyou-reviews__text p {
        font-size: pxToRem(20);
        line-height: pxToRem(32);
        font-weight: 500;
        text-align: center;
        color: $font-color-dark;
        display: -webkit-box;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /*! autoprefixer: on */
        -webkit-line-clamp: 3;
        max-height: calc(3rem + 120%);
        overflow: hidden;
        transition: all 5s cubic-bezier(0, 1, 0, 1);
    }

    .swiper-slide-active .trustyou-reviews__text {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: calc(-50px);
            left: calc(-50px - 48px);
            width: 48px;
            height: 46px;
            background-image: inline-svg('quotation-mark', $color-primary, transparent);
            transform: rotate(180deg);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: calc(-50px - 48px);
            width: 48px;
            height: 46px;
            background-image: inline-svg('quotation-mark', $color-primary, transparent);
        }
    }

    .trustyou-reviews__score {
        display: flex;
        margin-top: $spacing-m;
    }

    .rating__stars {
        margin-right: 16px;
    }

    span.rating__date {
        font-size: pxToRem(18);
        line-height: 120%;
        font-weight: 500;
        color: $font-color-dark;
    }

    .button.button--special {
        margin-top: $spacing-m;
        text-decoration: initial;
        &::before {
            border-radius: 50% 0 50% 50% !important;
            transform: rotate(90deg) !important;
        }
    }

    .trustyou-reviews__text p.showContent {
        line-height: pxToRem(32); ;
        height: auto !important;
        max-height: 100% !important;
        -webkit-line-clamp: 100 !important;
        transition: all .3s ease;
    }

    &.slider {
        overflow: hidden;
    }

    .swiper-button-prev {
        top: $spacing-m;
        left: 0;
    }

    .swiper-button-next {
        top: $spacing-m;
        right: 0;
    }

}

.trustyou {
    margin-top: $spacing-l;
    //Sources
    .trustyou-sources {
        margin-bottom: $spacing-m;
    }

    .trustyou-sources__title {
        padding-bottom: 12px;
        font-size: pxToRem(18);
        font-weight: 500;
        color: $font-color-dark;
        border-bottom: 3px solid $color-primary;
    }

    .trustyou-sources__item {
        padding: 10px 0 8px;
        font-weight: 300;
        border-bottom: 1px solid $color-black;

        .sources__rating {
            display: flex;
            align-items: center;
            line-height: 120%;
            //font-weight: 500;
            span {
                padding-left: 8px;
            }
        }

        &:last-child {
            border-bottom: 2px solid $color-primary;
        }
    }

    //Reviews
    .trustyou-reviews__item {
        margin-bottom: $spacing-xs;
    }

    .trustyou-reviews__review {
        padding: 16px 28px;
        background-color: $color-white;
        border-radius: 32px 32px 0 0;
    }

    .trustyou-reviews__title {
        margin-bottom: 8px;
        font-size: pxToRem(18);
        font-weight: 500;
        color: $font-color-dark;
    }

    .trustyou-reviews__score {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: $font-color-dark;
        span {
            margin-left: 8px;
        }
    }

    .trustyou-reviews__text {
        margin: 16px 0;

        p {
            position: relative;
            display: inline-block;
            padding: 0 $spacing-m;
        }

        p::before {
            content: '';
            position: absolute;
            bottom: -16px;
            left: 0;
            width: 25px;
            height: 23px;
            background-image: inline-svg('quotation-mark', $color-primary, transparent);
            background-size: 25px 23px;
            transform: rotate(180deg);
        }

        p::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 23px;
            background-image: inline-svg('quotation-mark', $color-primary, transparent);
            background-size: 25px 23px;
            transform: rotate(180deg);
        }
    }

    .trustyou-reviews__hotel {
        padding: 16px $spacing-s;
        margin-top: 2px;
        background: $color-white;
        border-radius: 0 0 0 32px;
        cursor: pointer;
    }

    .trustyou-reviews__response-title {
        font-weight: 500;

        span {
            position: relative;
        }

        span::before {
            content: '';
            position: absolute;
            top: auto;
            right: calc(-23px - 16px);
            width: 23px;
            height: 23px;
            margin-right: 0;
            //background-image: inline-svg('arrow-m', $color-white);
            background-size: 11px 11px;
            background-color: $color-primary;
            background-position: 50%;
            background-repeat: no-repeat;
            border-radius: 50% 50% 0 50%;
            border: 1px solid #fff;
        }

        span::after {
            content: '';
            position: absolute;
            top: auto;
            right: calc(-23px - 16px);
            width: 23px;
            height: 23px;
            background-image: inline-svg('arrow-m', $color-white);
            background-size: 11px 11px;
            background-position: 50%;
            background-repeat: no-repeat;
            transform: rotate(90deg);
            transition: transform .3s ease;
        }

        &.open span::after {
            transform: rotate(270deg);
            transition: transform .3s ease;
        }

    }

    .trustyou-reviews__response-text {
        display: none;
        overflow: hidden;
        padding-top: 4px;
    }

}

.star {
    display: inline-block;
    width: 27px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 27px 24px;
    background-position: center center;

    &.active {
        background-image: inline-svg('star', $color-primary);
    }

    &.inactive {
        background-image: inline-svg('star', #CCCCCC);
    }
}

@include media-breakpoint-down(lg) {
    .review-wrapper {
        padding-top: $spacing-m;

        .container {
            padding-left: $spacing-s !important;
            padding-right: $spacing-s !important;
        }
    }

    .trustyou--onpage {
        .swiper-button-prev, .swiper-button-next {
            top: -7px ;
        }
    }
}

@include media-breakpoint-down(md) {
    .trustyou {
        .trustyou-sources__anzahl {
            display: none;
        }

        .trustyou-sources__item {
            padding-bottom: 10px;
        }

        .trustyou-sources__rating {
            display: none;
        }
    }

    .review-wrapper {
        padding-top: initial;
    }

    .trustyou-onpage {
        .trustyou-reviews__text {
            min-width: initial;

            &::before, &::after {
                content: none !important;
            }
        }

        .swiper-button-prev, .swiper-button-next {
            top: 50%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .trustyou {
        .trustyou-reviews__text {
            p {
                padding: 0 $spacing-s;
            }

            p::before {
                bottom: -20px;
                left: 0;
                width: 20px;
                height: 18px;
                background-image: inline-svg('quotation-mark', $color-primary, transparent);
                background-size: 20px 18px;
            }

            p::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 18px;
                background-image: inline-svg('quotation-mark', $color-primary, transparent);
                background-size: 20px 18px;
                transform: rotate(180deg);
            }
        }
    }

    .trustyou--onpage {
        .container {
            max-width: 100%;
            padding-left: 14px !important;
            padding-right: 14px !important;
        }

        .slider {
            overflow: hidden !important;

            .swiper {
                overflow: visible !important;
            }
        }

        .swiper-button-prev, .swiper-button-next {
            width: 50px;
            height: 50px;
            background-image: inline-svg('arrow-l', $color-white);
            background-size: 24px 24px;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    }
}