.container--fluid {
    max-width: inherit !important;
}

.article__intro {
    margin: $spacing-xl 0 0;
}

.headline--topline, .multipage--topline {
    font-size: pxToRem(17);
    color: $font-color-dark;
}

.headline--headline, .multipage--headline {
    margin: $spacing-xs 0 $spacing-m;
}

.content {
    margin-left: $spacing-s;
    p {
        font-weight: 300;
    }

    hr + p {
        font-size: pxToRem(15);
        color: $font-color-dark;
    }
}

.multipage--content {
    margin: 0 0 $spacing-m $spacing-s;
}

.article__body .price {
    position: relative;
    margin: 56px 0 24px $spacing-s !important;
    font-size: pxToRem(28);
    line-height: pxToRem(34);
    font-weight: 500;
    color: $font-color-dark;

    &::before {
        content: '';
        position: absolute;
        top: -28px;
        left: 0;
        height: 1px;
        width: 90px;
        background-color: $color-black;
    }
}

/* || CTAs */

.cta {
    margin-top: $spacing-m;
}

.cta__item {
    display: inline-block;
}

.cta__item:not(:last-child) {
    margin-right: $spacing-s;
}

/* || Room */

.buttons__container--room {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacing-m;

    .button, .cta {
        margin-top: initial;
        margin-bottom: 16px;
        &:not(:last-child) {
            margin-right: 32px;
        }
    }

    .button--large {
        text-decoration: underline !important;
    }
}

.hint {
    margin-left: $spacing-s !important;
    font-size: pxToRem(15);
    font-weight: 400;
    color: $font-color-dark;
}

.form__hide {
    display: none;
}

.article__feedback {
    margin: $spacing-xxl 0;
}

/* || Hotelklassifizierung */

.reviews--hotelstars {
    display: flex;
    margin-top: 160px;
}

/* || Content2 SEO */

.content--seo {
    .content {
        margin-left: initial;
    }

    hr {
        margin-left: pxToRem(36) !important;
    }

    p:first-child:has(+h2) {
        color: $color-black;
        margin-left: initial;
    }

    p:not(:last-child) {
        margin: pxToRem(12) pxToRem(0) pxToRem(12) pxToRem(36);
    }

    p:last-child {
        margin: pxToRem(12) pxToRem(0) pxToRem(0) pxToRem(36);
    }
}

@include media-breakpoint-down(lg) {
    .reviews--hotelstars {
        justify-content: center;
        margin-top: 28px;
    }
}

@include media-breakpoint-down(md) {
    .content {
        margin-left: initial;
    }

    .article__body .price {
        margin-left: initial !important;
    }

    .hint {
        margin-left: initial !important;
    }

    .article__feedback {
        margin: $spacing-l 0;
    }

    .content--seo {
        hr {
            margin-left: initial !important;
        }

        p:not(:last-child) {
            margin-left: initial;
        }

        p:last-child {
            margin-left: initial;
        }
    }
}

@include media-breakpoint-down(sm) {
    .headline--headline, .multipage--headline {
        margin: $spacing-xs 0;
    }

    .article__intro {
        margin-top: $spacing-mobile;
    }

    .cta {
        margin-top: $spacing-xs;
    }

    .cta__item:not(:last-child) {
        margin-bottom: 14px;
    }

    .article__body .price {
        margin-left: 0 !important;
    }
}