$header-height: 80px;

.keyvisual {
    min-height: 200px;
    position: relative;
    height: auto; /*exact img size*/

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(180deg, rgba($color-black, .4) 0%, rgba($color-black, 0) 100%);
    }
}

.keyvisual--is-empty {
    height: $header-height !important;
}

.keyvisual .image {
    position: unset;
    height: 100%;
}

.keyvisual .swiper {
    overflow: hidden !important;
    height: 100%;
}

.keyvisual .swiper-button-prev, .keyvisual .swiper-button-next {
    display: none;
}

body[class*="view-frontpage"] .keyvisual,
.keyvisual__big {
    //height: 100vh;
    height: 100%;
}

body[class*="view-frontpage"] .kv__scroll, .keyvisual__big .kv__scroll {
    position: absolute;
    left: 50%;
    bottom: $spacing-s;
    z-index: 10;
    width: 34px;
    height: 34px;
    animation: bounce 1s infinite 500ms;

    &--button {
        display: block;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: rgba($color-white, .9);
        background-image: inline-svg('arrow-m', $color-black, transparent);
        background-size: 16px 16px;
        background-position: center center;
        background-repeat: no-repeat;
        transform: translateX(-50%) rotate(90deg);
    }
}

.claim {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 25%;
    background: rgba(0, 0, 0, .12);
    pointer-events: none;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.kv__button {
    display: none;
}

@include media-breakpoint-up(lg) {
    .claim img {
        padding-top: 150px;
    }
}

@include media-breakpoint-up(md) {

    .keyvisual {
        //height: calc(70vh - 80px /*HeaderHeight*/);
        height: 70vh;
    }

    body[class*="view-frontpage"] .keyvisual,
    .keyvisual__big {
        //height: calc(90vh - 80px /*HeaderHeight*/);
        height: 100vh;
    }
}

@media(max-width: 1325px) {
    .kv__scroll {
        display: none;
    }

    .kv__button {
        position: absolute;
        bottom: $spacing-xs;
        z-index: 20;
        display: flex;
        justify-content: center;
        width: 100%;

        .button--booknow {
            display: flex;
            margin: 0 14px;
            box-shadow: 0 2px 12px rgba($color-black, 0.3);
        }
    }
}

@include media-breakpoint-down(xl) {
    .claim {
        padding: 0 20%;
    }
}

@include media-breakpoint-down(lg) {
    body[class*="view-frontpage"] .keyvisual,
    .keyvisual__big {
        //height: calc(90vh - 80px /*HeaderHeight*/);
        height: 70vh;
    }
}

@include media-breakpoint-down(sm) {
    .keyvisual .swiper-wrapper {
        margin-left: initial;
    }

    .claim {
        left: 0;
        right: 0;
        width: 100%;
        padding: 0 25px;
        //font-size: calc(30px + (80 - 40) * ((100vw - 360px) / (767 - 360)));
        text-align: center;
        span {
            display: block
        }
    }
}


