
.video {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: $color-gray-10;
}

.video__image {
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 0.3s;
}

.video.ready .video__image {
    opacity: 0;
}

.video__wrap {
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
}

.video.ready .video__wrap {
    opacity: 1;
}

.video.portrait .video__wrap {
    padding-right: 267%;
    height: 150%;
}

.video.landscape .video__wrap {
    padding-bottom: 57%;
    width: 100%;
}

.video__wrap video,
.video__wrap iframe {
    pointer-events: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video:not(.empty):before {
    content: '\00a0';
    z-index: 1;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    display: block;
    width: 30px;
    height: 30px;
    border: 2px solid $color-gray-30;
    border-top-color: transparent;
    border-radius: 500px;
    animation: SxSpinner 0.85s linear infinite;
}
